/* eslint-disable max-statements */
/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { Autocomplete, MultipleSelect, PrimaryButton, TextField, Form, SecondaryButton, Spinner } from '@get-e/react-components';
import {
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Rating,
    StandardTextFieldProps,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { DateRange, DateRangePicker, TimePicker } from '@mui/x-date-pickers-pro';
import { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useIsFetching, useMutation } from 'react-query';
import { useNavigate } from 'react-router';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import {
    BUTTON_ALLOWED_ROOMS,
    BUTTON_MINIMUM_STAR_RATING,
    BUTTON_REQUEST_INCLUDE_BREAKFAST,
    BUTTON_REQUEST_INCLUDE_PARKING,
    BUTTON_SEND_REQUEST,
    INPUT_REQUEST_CHECKIN_TIME,
    INPUT_REQUEST_CHECKINOUT_DATE,
    INPUT_REQUEST_CHECKOUT_TIME,
    INPUT_REQUEST_SEARCH_LOCATION,
    INPUT_REQUEST_SPECIAL_REQUEST,
    INPUT_SEARCH_ACCOUNT_NAME,
    INPUT_SEARCH_GUEST_NAME,
    SCREEN_CREATE_EDIT_REQUEST,
} from '../../../amplitude/amplKeys';
import { GooglePlacesAutocomplete } from '../../../components/GooglePlacesAutocomplete';
import LabeledData from '../../../components/LabeledData';
import PageTitle from '../../../components/PageTitle';
import { COLORS } from '../../../constans/colors';
import { ALL_CUSTOMERS, ALL_TRAVELLERS } from '../../../constans/queryKeys';
import { REQUESTS, REQUESTS_PENDING } from '../../../constans/urlPaths';
import { useAuth } from '../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import useEffectAsync from '../../../helpers/useEffectAsync';
import allValid from '../../../helpers/validation/allValid';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { Validated } from '../../../helpers/validation/Validator';
import and from '../../../helpers/validation/validators/and';
import isAfterTime from '../../../helpers/validation/validators/isAfterTime';
import isFilledObject from '../../../helpers/validation/validators/isFilledObject';
import isFilledObjectAttributes from '../../../helpers/validation/validators/isFilledObjectAttributes';
import isNotNull from '../../../helpers/validation/validators/isNotNull';
import { SCOPES } from '../../../permissions/permissionMaps';
import PermissionsGate from '../../../permissions/PermissionsGate';
import { getTimeZone } from '../../../services/getTimeZone';
import { createRequest } from '../../../services/request';
import { mapAddResponseToTraveller } from '../../../services/traveller';
import { dayStyles, textFieldStyles } from '../../../styles/DateTimeRangePicker';
import { AddressRequest, Customer } from '../../accounts/api/types';
import { useCustomers } from '../../accounts/api/useCustomers';
import AddNewTravellerModal from '../../hotels/addNewTravellerModal/AddNewTravellerModal';
import { DEAFULT_ADD_BUTTON_OPTION, NO_RESULTS_OPTION } from '../../hotels/api/constants';
import { AddTravellerResponse, Traveller } from '../../hotels/api/types';
import { useBenefits } from '../../hotels/api/useBenefits';
import { useRoomTypes } from '../../hotels/api/useRoomTypes';
import { useTravellers } from '../../hotels/api/useTravellers';
import { Benefits, RoomTypes } from '../api/types';

const useStyles = makeStyles({
    headingContainer: {
        marginBottom: '1.5rem',
    },
    buttonContainer: {
        marginTop: '2rem',
    },
    secondaryText: {
        color: COLORS.DARK_GRAY,
    },
    noResults: {
        textAlign: 'center',
        padding: '.75rem 1rem',
    },
    addNewContainer: {
        borderTop: `1px solid ${COLORS.DARK_GRAY}`,
        padding: '.75rem 1rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    boxItem: {
        padding: '.75rem 1rem',
        '&:hover': {
            backgroundColor: COLORS.LIGHT_GRAY,
        },
    },
    starRatingTitle: {
        marginBottom: '.5rem',
    },
    cancelButton: {
        marginLeft: '2rem',
        color: COLORS.BLUE,
    },
    searchLoader: {
        position: 'absolute',
        right: '40px',
        top: '45%',
        zIndex: '100',
    },
    gridContainer: {
        gridTemplateAreas: "'gridItem1' 'gridItem2' 'gridItem3'",
    },
    gridContainerMobile: {
        display: 'grid',
        gridTemplateAreas: "'gridItem1' 'gridItem3' 'gridItem2'",
    },
});

interface ValidationObject {
    checkInOutDate: Validated<Dayjs, InputError>;
    traveller: Validated<Traveller | null, InputError>;
    address?: Validated<AddressRequest, InputError>;
    account?: Validated<Customer, InputError>;
    checkInTime?: Validated<Dayjs | undefined, InputError>;
    checkOutTime?: Validated<Date, InputError>;
}

const DEFAULT_ROOM_TYPES = [RoomTypes.DOUBLE_ROOM, RoomTypes.SINGLE_ROOM];

const CreateRequest = () => {
    const [address, setAddress] = useState<AddressRequest>({} as AddressRequest);

    const [selectedDateRange, setSelectedDateRange] = useState<DateRange<Dayjs>>([null, null]);

    const [checkInTime, setCheckInTime] = useState<Dayjs | null>();
    const [checkOutTime, setCheckOutTime] = useState<Dayjs | null>();
    const [openInTime, setOpenInTime] = useState(false);
    const [openOutTime, setOpenOutTime] = useState(false);
    const [isTimeIncluded, setIsTimeIncluded] = useState(false);

    const [traveller, setTraveller] = useState<Traveller | null>(null);
    const [account, setAccount] = useState<Customer | null>(null);
    const [roomTypeIds, setRoomTypeIds] = useState<string[]>([]);
    const [ratingStar, setRatingStar] = useState<number>(3);
    const [comment, setComment] = useState('');
    const [includeBreakfast, setIncludeBreakfast] = useState(true);
    const [includeParking, setIncludeParking] = useState(false);
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [inputAccountValue, setInputAccountValue] = useState('');

    const [dateRangeError, setDateRangeError] = useState<InputError | null>(null);
    const [checkInTimeError, setCheckInTimeError] = useState<InputError | null>(null);
    const [checkOutTimeError, setCheckOutTimeError] = useState<InputError | null>(null);

    const [travellerError, setTravellerError] = useState<InputError | null>(null);
    const [accountError, setAccountError] = useState<InputError | null>(null);
    const [addressError, setAddressError] = useState<InputError | null>(null);
    const [isSearchTravellers, setIsSearchingTravellers] = useState(false);

    const { showNotification } = useNotificationContext();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const classes = useStyles();
    const { user } = useAuth();
    const isMobile = useMediaQuery('(max-width:600px)');

    const { accounts } = useCustomers(inputAccountValue, user?.isBackoffice);

    const customerId = useMemo(() => {
        return user?.isBackoffice ? (account?.id ?? -1) : (user?.customer?.id ?? -1);
    }, [user, account]);

    const { travellers = [], refetch: refetchTravellersList } = useTravellers({
        search: inputValue,
        customerId,
    });

    const { roomTypes } = useRoomTypes();
    const { benefits } = useBenefits();

    const isFetchingTravellers = Boolean(useIsFetching([ALL_TRAVELLERS + inputValue]));

    const isFetchingAccounts = Boolean(useIsFetching([ALL_CUSTOMERS + inputAccountValue]));

    const roomTypesValues = useMemo(() => new Map(roomTypes?.map(roomType => [roomType.id, roomType.name])), [roomTypes]);

    useEffect(() => {
        logAmplitudeEvent(SCREEN_CREATE_EDIT_REQUEST);
    }, []);

    useEffect(() => {
        setIsTimeIncluded(Boolean(checkInTime) || Boolean(checkOutTime));
    }, [checkInTime, checkOutTime]);

    useEffect(() => {
        logAmplitudeEvent(INPUT_REQUEST_SEARCH_LOCATION);
    }, [address]);

    useEffectAsync(async () => {
        if (address && Object.keys(address).length !== 0) {
            const timeZone = await getTimeZone(
                address.lat ?? '',
                address.long ?? '',
                (selectedDateRange[0] ? selectedDateRange[0].valueOf() : dayjs().valueOf()) / 1000
            );

            // eslint-disable-next-line require-atomic-updates
            address.timeZone = timeZone.timeZoneId;
        }
    }, [address, selectedDateRange[0]]);

    useEffect(() => {
        if (!isOpenModal && inputValue.length > 2) {
            refetchTravellersList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenModal]);

    useEffect(() => {
        setIsSearchingTravellers(inputValue.length <= 2);
    }, [inputValue]);

    const travellersOptions = useMemo(() => {
        return [...travellers, DEAFULT_ADD_BUTTON_OPTION];
    }, [travellers]);

    const onSetIds = (ids: string[]) => {
        setRoomTypeIds(ids);
    };

    const { mutate: createRequestMutation, isLoading: isLoadingCreate } = useMutation(createRequest, {
        onSuccess: data => {
            const requestId = data.data.id;

            navigate(`${REQUESTS}${requestId}`, {
                state: {
                    id: requestId,
                },
            });
            showNotification('Request successfully created.', Severity.Info);
        },
        onError: (error: AxiosError<Error>) => {
            showNotification(error.response?.data?.message ?? t('errors.retry'), Severity.Error);
        },
    });

    const validateFormFields = (): boolean => {
        setCheckInTimeError(null);
        setCheckOutTimeError(null);

        const validated: ValidationObject = {
            checkInOutDate: and(isNotNull(selectedDateRange?.[0], InputError.Required), () =>
                isNotNull(selectedDateRange?.[1], InputError.Required)
            ),
            traveller: isFilledObjectAttributes(traveller, InputError.Required),
            address: isFilledObject(address, InputError.Required),
            account: user?.isBackoffice
                ? isFilledObjectAttributes(account, InputError.Required)
                : // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  ({ isValid: true, error: null } as any),
        };

        const isSameDate =
            selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) ===
            selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']);

        if (isTimeIncluded) {
            validated.checkInTime = isNotNull(checkInTime, InputError.Required);
            validated.checkOutTime = and(isNotNull(checkOutTime, InputError.Required), () =>
                isSameDate
                    ? isAfterTime(checkOutTime?.toDate() || null, checkInTime?.toDate() || null, InputError.InvalidAfterTime)
                    : ({
                          isValid: true,
                          value: new Date(),
                      } as Validated<Date, InputError>)
            );
        }

        if (!allValid(validated)) {
            setDateRangeError(validated.checkInOutDate.isValid ? null : InputError.Required);

            if (isTimeIncluded) {
                setCheckInTimeError(validated.checkInTime?.isValid ? null : validated.checkInTime?.error || null);
                setCheckOutTimeError(validated.checkOutTime?.isValid ? null : validated.checkOutTime?.error || null);
            }

            setTravellerError(validated.traveller.isValid ? null : validated.traveller?.error);

            setAddressError(validated.address?.isValid ? null : validated.address?.error || null);

            setAccountError(validated.account?.isValid ? null : validated.account?.error || null);
            return false;
        }

        return true;
    };

    const renderAddNewButton = () => {
        return (
            <Box
                component="li"
                className={classes.addNewContainer}
                onClick={() => {
                    setIsOpenModal(true);
                }}
            >
                + Add new
            </Box>
        );
    };

    const findBenefitId = (benefitName: Benefits): number => {
        const id = benefits?.find(benefit => benefit.name === benefitName)?.id;

        return id ? parseInt(id, 10) : 0;
    };

    const getBenefitIds = (): string[] => {
        const benefitIds: string[] = [];

        if (includeBreakfast) {
            const benefitId = findBenefitId(Benefits.INCLUDE_BREAKFAST);

            if (benefitId) {
                benefitIds.push(benefitId.toString());
            }
        }

        if (includeParking) {
            const benefitId = findBenefitId(Benefits.INCLUDE_PARKING);

            if (benefitId) {
                benefitIds.push(benefitId.toString());
            }
        }

        return benefitIds;
    };

    const handleSubmit = () => {
        logAmplitudeEvent(BUTTON_SEND_REQUEST);

        if (validateFormFields()) {
            const timeIn = checkInTime?.format(TIME_FORMATS['HH:mm:ss']) || '';
            const timeOut = checkOutTime?.format(TIME_FORMATS['HH:mm:ss']) || '';

            createRequestMutation({
                checkInDate: selectedDateRange?.[0]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                checkOutDate: selectedDateRange?.[1]?.format(DATE_FORMATS['YYYY-MM-DD']) || '',
                isTimeIncluded: Boolean(timeIn) && Boolean(timeOut),
                checkInTime: timeIn,
                checkOutTime: timeOut,
                minimumStarRating: ratingStar,
                comments: comment,
                roomTypeIds,
                benefitIds: getBenefitIds(),
                requestAddress: {
                    shortName: address.name ?? '',
                    addressLine: address.address_line ?? '',
                    postalCode: address.postal_code ?? '',
                    city: address.city ?? '',
                    stateProvince: address.state_province ?? '',
                    countryCode: address.country_code ?? '',
                    lat: address.lat?.toString() ?? '',
                    long: address?.long?.toString() ?? '',
                    timeZone: address?.timeZone ?? '',
                },
                travellerId: traveller?.id,
                customerId,
            });
        }
    };

    const handleCheckInTime = (newCheckInTime: Dayjs | null) => {
        setCheckInTime(newCheckInTime);
        fixCheckOutTime(newCheckInTime);
        setCheckInTimeError(null);
    };

    const fixCheckOutTime = (newCheckInTime: Dayjs | null) => {
        if (
            dayjs(selectedDateRange?.[0]).format(DATE_FORMATS['YYYY-MM-DD']) ===
            dayjs(selectedDateRange?.[1]).format(DATE_FORMATS['YYYY-MM-DD'])
        ) {
            if (dayjs(newCheckInTime).hour() > dayjs(checkOutTime).hour()) {
                setCheckOutTime(dayjs(newCheckInTime).add(1, 'hours'));
            }

            if (
                dayjs(newCheckInTime).hour() === dayjs(checkOutTime).hour() &&
                dayjs(newCheckInTime).minute() > dayjs(checkOutTime).minute()
            ) {
                setCheckOutTime(dayjs(newCheckInTime).add(30, 'minutes'));
            }
        }
    };

    const handleBack = () => {
        navigate(REQUESTS_PENDING);
    };

    const handleTravellerCreated = (travellerCreated: AddTravellerResponse) => {
        setInputValue(`${travellerCreated.first_name} ${travellerCreated.last_name}`);
        setTraveller(mapAddResponseToTraveller(travellerCreated));
        setIsOpenModal(false);
    };

    return (
        <>
            <div className={classes.headingContainer}>
                <PageTitle title="New request" />
            </div>
            <Form onSubmit={handleSubmit}>
                <Grid container spacing={4} className={isMobile ? classes.gridContainerMobile : classes.gridContainer}>
                    <Grid item xs={12} sm={6} sx={{ gridArea: 'gridItem1' }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData label="Request" labelColumnNumber={12} labelColor={COLORS.BLUE} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <GooglePlacesAutocomplete
                                            error={addressError}
                                            helperText={getHelperText(addressError, t)}
                                            onChangeError={setAddressError}
                                            onChangeAddress={setAddress}
                                            label="Search airports, cities or points of interest"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DateRangePicker
                                            localeText={{
                                                start: 'Check-in',
                                                end: 'Check-out',
                                            }}
                                            value={selectedDateRange}
                                            onChange={newValue => {
                                                setSelectedDateRange(newValue);
                                                setDateRangeError(null);
                                                logAmplitudeEvent(INPUT_REQUEST_CHECKINOUT_DATE);
                                            }}
                                            format={DATE_FORMATS['DD MMM YYYY']}
                                            slotProps={{
                                                field: { dateSeparator: 'to' },
                                                textField: {
                                                    ...(textFieldStyles as StandardTextFieldProps),
                                                    required: true,
                                                    error: dateRangeError !== null,
                                                    helperText: getHelperText(dateRangeError, t),
                                                },
                                                day: {
                                                    sx: dayStyles,
                                                },
                                            }}
                                            disablePast={!user?.isBackoffice}
                                        />
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <TimePicker
                                            label="Check-in time"
                                            value={checkInTime}
                                            onChange={newValue => handleCheckInTime(newValue)}
                                            format={TIME_FORMATS['HH:mm A']}
                                            onAccept={() => setOpenInTime(true)}
                                            minutesStep={15}
                                            onClose={() => {
                                                logAmplitudeEvent(INPUT_REQUEST_CHECKIN_TIME);
                                                setOpenInTime(false);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    error: checkInTimeError !== null,
                                                    helperText: getHelperText(checkInTimeError, t),
                                                    variant: 'filled',
                                                    inputProps: { readOnly: true },
                                                    onClick: () => setOpenInTime(true),
                                                },
                                                field: { clearable: true },
                                            }}
                                            open={openInTime}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Typography></Typography>
                                    </Grid>
                                    <Grid item xs={5.5}>
                                        <TimePicker
                                            label="Check-out time"
                                            value={checkOutTime}
                                            onChange={newValue => {
                                                setCheckOutTime(newValue);
                                                setCheckOutTimeError(null);
                                            }}
                                            format={TIME_FORMATS['HH:mm A']}
                                            onAccept={() => setOpenOutTime(true)}
                                            minutesStep={15}
                                            onClose={() => {
                                                logAmplitudeEvent(INPUT_REQUEST_CHECKOUT_TIME);
                                                setOpenOutTime(false);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    error: checkOutTimeError !== null,
                                                    helperText: getHelperText(checkOutTimeError, t),
                                                    variant: 'filled',
                                                    inputProps: { readOnly: true },
                                                    onClick: () => setOpenOutTime(true),
                                                },
                                                field: { clearable: true },
                                            }}
                                            open={openOutTime}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Special requests (optional)"
                                            value={comment}
                                            onChange={event => {
                                                setComment(event.target.value);
                                            }}
                                            onFocus={() => logAmplitudeEvent(INPUT_REQUEST_SPECIAL_REQUEST)}
                                            multiline
                                            rows={4}
                                            helperText=""
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Card variant="outlined" sx={{ overflow: 'visible' }}>
                            <CardContent>
                                <PermissionsGate>
                                    <Grid container columnSpacing={1} rowSpacing={2}>
                                        <Grid item xs={12} md={6} lg={6}>
                                            <LabeledData label="Account in use" labelColumnNumber={12} labelColor={COLORS.BLUE} />
                                        </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                            mb="2rem"
                                            sx={{
                                                position: 'relative',
                                            }}
                                        >
                                            {isFetchingAccounts && <Spinner className={classes.searchLoader} size={25} />}
                                            <Autocomplete
                                                required
                                                filterOptions={options => options}
                                                label="Search by account name"
                                                options={accounts}
                                                getOptionLabel={option => option.name}
                                                isError={accountError !== null}
                                                helperText={getHelperText(accountError, t)}
                                                renderOption={(prop, option: Customer) => {
                                                    return (
                                                        <Box component="li" {...prop} key={option.id} className={classes.boxItem}>
                                                            <span>{option.name}</span>
                                                        </Box>
                                                    );
                                                }}
                                                value={account}
                                                onChange={(_, newValue) => {
                                                    setAccount(newValue ?? null);
                                                    setAccountError(null);
                                                    logAmplitudeEvent(INPUT_SEARCH_ACCOUNT_NAME);
                                                }}
                                                onInputChange={(event, newInputValue) => {
                                                    setInputAccountValue(newInputValue);
                                                }}
                                                noOptionsText="No result found"
                                            />
                                        </Grid>
                                    </Grid>
                                </PermissionsGate>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData label="Traveller" labelColumnNumber={12} labelColor={COLORS.BLUE} />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            position: 'relative',
                                        }}
                                    >
                                        {isFetchingTravellers && <Spinner className={classes.searchLoader} size={25} />}
                                        <Autocomplete
                                            required
                                            disabled={user?.isBackoffice && !account}
                                            filterOptions={options => {
                                                if (options.length === 1) {
                                                    options.unshift(NO_RESULTS_OPTION);
                                                }

                                                return options;
                                            }}
                                            isError={travellerError !== null}
                                            helperText={
                                                user?.isBackoffice && !account
                                                    ? `Firstly, select account for traveller. ${getHelperText(travellerError, t)}`
                                                    : getHelperText(travellerError, t)
                                            }
                                            label="Add traveller name"
                                            options={travellersOptions}
                                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                                            renderOption={(prop, option: Traveller) => {
                                                if (option.id === 'NO_RESULTS') {
                                                    if (isSearchTravellers) {
                                                        return (
                                                            <Box
                                                                component="li"
                                                                {...prop}
                                                                className={classes.noResults}
                                                                onClick={() => {}}
                                                            >
                                                                {inputValue.length ? (
                                                                    <Spinner size={25} />
                                                                ) : (
                                                                    'Start typing to search'
                                                                )}
                                                            </Box>
                                                        );
                                                    }

                                                    return (
                                                        <Box
                                                            component="li"
                                                            {...prop}
                                                            className={classes.noResults}
                                                            onClick={() => {}}
                                                        >
                                                            No results found
                                                        </Box>
                                                    );
                                                }

                                                if (option.id === 'ADD') {
                                                    return (
                                                        <PermissionsGate scopes={[SCOPES.canAddTraveller]}>
                                                            {renderAddNewButton()}
                                                        </PermissionsGate>
                                                    );
                                                }

                                                return (
                                                    <Box component="li" {...prop} key={option.id} className={classes.boxItem}>
                                                        <span>
                                                            {option.firstName} {option.lastName}
                                                        </span>
                                                        <span className={classes.secondaryText}>
                                                            &nbsp;
                                                            {option.employeeNumber}
                                                        </span>
                                                    </Box>
                                                );
                                            }}
                                            value={traveller}
                                            onChange={(_, newValue) => {
                                                setTraveller(newValue ?? null);
                                                setTravellerError(null);
                                                logAmplitudeEvent(INPUT_SEARCH_GUEST_NAME);
                                            }}
                                            onInputChange={(event, newInputValue) => {
                                                setInputValue(newInputValue);
                                            }}
                                            noOptionsText={renderAddNewButton()}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                        <Typography mb={'2rem'} mt={'2rem'}>
                            By clicking on “Send request” you will add a new request to your request list, where current, upcoming
                            and past booking requests can be managed.
                        </Typography>
                        <div className={classes.buttonContainer}>
                            <PrimaryButton onClick={handleSubmit} submitsForm loading={isLoadingCreate}>
                                Send request
                            </PrimaryButton>
                            <SecondaryButton onClick={handleBack} className={classes.cancelButton}>
                                Go to requests
                            </SecondaryButton>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} sx={{ gridArea: 'gridItem3' }}>
                        <Card variant="outlined">
                            <CardContent>
                                <Grid container columnSpacing={1} rowSpacing={2}>
                                    <Grid item xs={12} md={6} lg={6}>
                                        <LabeledData label="Travel policy" labelColumnNumber={12} labelColor={COLORS.BLUE} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography component="legend" className={classes.starRatingTitle}>
                                            Minimum star-rating
                                        </Typography>
                                        <Rating
                                            value={ratingStar}
                                            onChange={(_, newValue) => {
                                                setRatingStar(newValue ?? 0);
                                                logAmplitudeEvent(BUTTON_MINIMUM_STAR_RATING);
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {roomTypes && Object.keys(roomTypes).length && (
                                            <MultipleSelect
                                                label="Allowed room type(s)"
                                                value={roomTypeIds}
                                                values={roomTypesValues}
                                                onSetIds={onSetIds}
                                                defaultSelectedValue={DEFAULT_ROOM_TYPES}
                                                onFocus={() => logAmplitudeEvent(BUTTON_ALLOWED_ROOMS)}
                                            />
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={includeBreakfast}
                                                    onChange={event => setIncludeBreakfast(event.target.checked)}
                                                    onFocus={() => logAmplitudeEvent(BUTTON_REQUEST_INCLUDE_BREAKFAST)}
                                                />
                                            }
                                            label="Include breakfast"
                                            sx={{ width: '100%' }}
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={includeParking}
                                                    onChange={event => setIncludeParking(event.target.checked)}
                                                    onFocus={() => logAmplitudeEvent(BUTTON_REQUEST_INCLUDE_PARKING)}
                                                />
                                            }
                                            label="Include parking"
                                            sx={{ width: '100%' }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                    {isOpenModal && (
                        <AddNewTravellerModal
                            isOpen={isOpenModal}
                            onClose={() => {
                                setIsOpenModal(false);
                            }}
                            onTravellerCreated={handleTravellerCreated}
                            customerId={customerId}
                        />
                    )}
                </Grid>
            </Form>
        </>
    );
};

export default CreateRequest;
