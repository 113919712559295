import { differenceInCalendarDays, differenceInHours, format, parseISO } from 'date-fns';

import dayjs from 'dayjs';
import { DATE_FORMATS } from './dateFormats';
import { TIME_FORMATS } from './timeFormats';

export const getCheckDate = (date: string) => {
    return format(parseISO(date), 'dd MMM');
};

export const getDifferenceInDays = (date1: string, date2: string) => differenceInCalendarDays(parseISO(date1), parseISO(date2));

export const getDifferenceInHours = (date1: string, date2: string) => differenceInHours(parseISO(date1), parseISO(date2));

export const getFormattedUtcDateTimeString = (
    dateTimeString: string,
    dateFormat: DATE_FORMATS,
    timeFormat: TIME_FORMATS,
    showTime?: boolean
) => {
    const timeFormatResolved = showTime ? ` (${timeFormat})` : '';
    const dateTimeFormat = `${dateFormat}${timeFormatResolved}`;

    return !dateTimeString || !dayjs.utc(dateTimeString).isValid() ? '' : dayjs.utc(dateTimeString).format(dateTimeFormat);
};

export const getFormattedDateTimeString = (
    dateTimeString: string,
    dateFormat: DATE_FORMATS,
    timeFormat: TIME_FORMATS,
    showTime?: boolean,
    withoutWrapperParentheses?: boolean
) => {
    const timeFormatResolved = withoutWrapperParentheses ? ` ${timeFormat}` : ` (${timeFormat})`;

    const dateTimeFormat = `${dateFormat}${showTime ? timeFormatResolved : ''}`;

    return !dateTimeString || !dayjs(dateTimeString).isValid() ? '' : dayjs(dateTimeString).format(dateTimeFormat);
};

export const getDaysMonthUtcRangeString = (startDateString?: string, endDateString?: string) => {
    if (!startDateString || !endDateString || !dayjs.utc(startDateString).isValid() || !dayjs.utc(endDateString).isValid()) {
        return '';
    }

    const startDate = dayjs.utc(startDateString);
    const endDate = dayjs.utc(endDateString);
    const startDateDayString = startDate.format(DATE_FORMATS.DD);
    const endDateDayString = endDate.format(DATE_FORMATS.DD);
    const startDateMonthString = startDate.format(DATE_FORMATS.MMM);
    const endDateMonthString = endDate.format(DATE_FORMATS.MMM);

    if (startDateMonthString === endDateMonthString) {
        return `${startDateDayString} - ${endDateDayString} ${startDateMonthString}`;
    }

    return `${startDateDayString} ${startDateMonthString === endDateMonthString ? '' : startDateMonthString} - ${endDateDayString} ${endDateMonthString}`;
};

export const getDaysMonthRangeString = (startDateString?: string, endDateString?: string) => {
    if (!startDateString || !endDateString || !dayjs(startDateString).isValid() || !dayjs(endDateString).isValid()) {
        return '';
    }

    const startDate = dayjs(startDateString);
    const endDate = dayjs(endDateString);
    const startDateDayString = startDate.format(DATE_FORMATS.DD);
    const endDateDayString = endDate.format(DATE_FORMATS.DD);
    const startDateMonthString = startDate.format(DATE_FORMATS.MMM);
    const endDateMonthString = endDate.format(DATE_FORMATS.MMM);

    if (startDateMonthString === endDateMonthString) {
        return `${startDateDayString} - ${endDateDayString} ${startDateMonthString}`;
    }

    return `${startDateDayString} ${startDateMonthString === endDateMonthString ? '' : startDateMonthString} - ${endDateDayString} ${endDateMonthString}`;
};
