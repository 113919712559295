import { useMemo } from 'react';
import { useQuery } from 'react-query';

import { ALL_INVOICES } from '../../../constans/queryKeys';
import { listInvoices } from '../../../services/invoice';
import { InvoiceVariables } from '../types';

export const useInvoices = (variables: InvoiceVariables) => {
    const queryKey = [ALL_INVOICES, variables];
    const queryFn = () => listInvoices(variables);

    const { data, refetch, isLoading, isRefetching } = useQuery(queryKey, queryFn, {
        keepPreviousData: false,
        refetchOnWindowFocus: false,
        retry: false,
        cacheTime: 500,
    });

    return useMemo(
        () => ({
            data: data || [],
            refetch,
            isLoading,
            isRefetching,
        }),
        [data, isLoading, isRefetching, refetch]
    );
};
