/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';

import { memo } from 'react';
import { COLORS } from '../../../../constans/colors';

interface PreviewSectionProps {
    goToPrevious: () => void;
    goToNext: () => void;
    fileUrl: string;
    fileType: string;
    fileName: string;
}

export const PreviewSection = memo(({ fileType, goToPrevious, goToNext, fileUrl, fileName }: PreviewSectionProps) => (
    <Grid item xs={12} sx={{ height: theme => theme.spacing(75) }}>
        <Box sx={{ height: '100%', overflow: 'hidden', position: 'relative' }}>
            {fileType?.includes('pdf') ? (
                <object
                    data={fileUrl}
                    type="application/pdf"
                    width="100%"
                    height="100%"
                    style={{ objectFit: 'contain' }}
                ></object>
            ) : fileType?.startsWith('image/') ? (
                <img
                    src={fileUrl}
                    alt={fileName}
                    style={{
                        transformOrigin: 'center',
                        maxHeight: '100%',
                        maxWidth: '100%',
                    }}
                />
            ) : (
                <Typography>No preview available for this file type</Typography>
            )}
            {/* {files.length > 1 && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: 0,
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        border: '1px solid black',
                        background: COLORS.SLATE_GREY,
                    }}
                    onClick={goToPrevious}
                >
                    <ArrowBack />
                </IconButton>
            )} */}
            {/* {files.length > 1 && (
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        right: 0,
                        transform: 'translateY(-50%)',
                        cursor: 'pointer',
                        border: '1px solid black',
                        background: COLORS.SLATE_GREY,
                    }}
                    onClick={goToNext}
                >
                    <ArrowForward />
                </IconButton>
            )} */}
        </Box>
    </Grid>
));
