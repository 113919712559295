import { NavigationBarFooter, NavigationBarLayout } from '@get-e/react-components';
import { useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FunctionComponent, ReactNode, useState, PropsWithChildren } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { BreadcrumbHeadNode } from '../../createRoutes';
import getValue from '../../helpers/getValue';
import { Environment, getCurrentEnvironment } from '../../helpers/onEnvironment';
import ErrorBoundaryPage from '../../pages/errorBoundaryPage/ErrorBoundaryPage';
import Breadcrumbs from '../Breadcrumbs';
import NavigationList from './NavigationList';
import UserMenuButton from './UserMenuButton';

const useStyles = makeStyles({
    userMenuButton: { marginLeft: '.25rem' },
    centerContainer: {
        '& > main > div': { maxWidth: 'none !important' },
    },
});

const Page: FunctionComponent<
    PropsWithChildren<{ breadcrumbs: BreadcrumbHeadNode; children: ReactNode; isFullContent?: boolean }>
> = ({ children, breadcrumbs, isFullContent }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const [menuClosed, setMenuClosed] = useState(Boolean(isMobile));
    const classes = useStyles();

    const navigationList = (() => <NavigationList />)();

    return (
        <div className={isFullContent ? classes.centerContainer : ''}>
            <NavigationBarLayout
                closed={menuClosed}
                onMenuClose={() => setMenuClosed(true)}
                onMenuOpen={() => setMenuClosed(false)}
                navigationList={navigationList}
                breadcrumbs={<Breadcrumbs node={breadcrumbs} />}
                environmentBadge={getValue(() => {
                    switch (getCurrentEnvironment()) {
                        case Environment.Local:
                            return 'Local';
                        case Environment.Staging:
                            return 'Staging';
                        case Environment.Production:
                            return undefined;
                        default:
                            throw new Error('Unhandled environment');
                    }
                })}
                actions={<UserMenuButton className={classes.userMenuButton} />}
                navigationBarFooter={<NavigationBarFooter closed={menuClosed} />}
                isLogoutMobileVisible
            >
                <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>{children}</ErrorBoundary>
            </NavigationBarLayout>
        </div>
    );
};

export default Page;
