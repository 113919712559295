import { useQuery } from 'react-query';

import { GET_BOOOKING_OPTIONS } from '../../../../constans/queryKeys';
import apiClient from '../../../../services/api';
import { INVOICE_BOOOKING_OPTIONS } from '../../../../services/routes';
import { BookingOption } from './types';

interface BookingOptionVariables {
    customerId: string;
    hotelId: string;
    search?: string;
    fromDateLocal?: string;
    toDateLocal?: string;
}

export const useBookingOptions = (variables: BookingOptionVariables) => {
    const { customerId, hotelId } = variables;

    const queryKey = [GET_BOOOKING_OPTIONS, variables];
    const queryFn = () => getBookingOptions(variables);

    const { data, isLoading, isError, isFetching, refetch } = useQuery(queryKey, queryFn, {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
        enabled: Boolean(customerId) && Boolean(hotelId),
    });

    return {
        data: (data as BookingOption[]) ?? [],
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getBookingOptions = async (variables: BookingOptionVariables): Promise<BookingOption[]> => {
    const { customerId, hotelId, search = '', fromDateLocal = '', toDateLocal = '' } = variables;

    const searchParams = new URLSearchParams({ customerId, hotelId, search, fromDateLocal, toDateLocal }).toString();
    const { data } = await apiClient.get<BookingOption[]>(`${INVOICE_BOOOKING_OPTIONS}?${searchParams}`);

    return data;
};
