import { MenuListItem } from '@get-e/react-components';
import {
    ExpandLess,
    ExpandMore,
    PlaylistAddCheckOutlined,
    PlaylistAddOutlined,
    TravelExploreOutlined,
} from '@mui/icons-material';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import LuggageOutlinedIcon from '@mui/icons-material/LuggageOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useFlags } from 'launchdarkly-react-client-sdk';
import React from 'react';
import { matchPath, useNavigate } from 'react-router-dom';

import {
    PEOPLE_ACTIVE,
    CREATE_REQUEST,
    PEOPLE,
    ACCOUNTS,
    ACCOUNTS_HOTELS,
    TRANSFER,
    REQUESTS,
    REQUESTS_PENDING,
    BOOKINGS,
    HOTEL_USERS_ACTIVE,
    BOOKINGS_PENDING,
    INVOICES,
} from '../../constans/urlPaths';
import { useIsHotelUser } from '../../context/AuthenticatedUserContext';
import { GetEIcon } from '../../images';
import { SCOPES } from '../../permissions/permissionMaps';
import PermissionsGate from '../../permissions/PermissionsGate';

function onPage(path: string): boolean {
    return Boolean(matchPath({ path }, window.location.pathname));
}

const useStyles = makeStyles({
    activeMenu: {
        backgroundColor: '#001261',
    },
    activeSubMenu: {
        fontWeight: '500',
        textShadow: '0.05rem 0.05rem gray',
    },
});

const NavigationList = () => {
    const [requestsOpen, setRequestsOpen] = React.useState(onPage(`${REQUESTS}/*`));
    const navigate = useNavigate();
    const classes = useStyles();
    const isHotelUser = useIsHotelUser();
    const { useFinancialReconcilliation } = useFlags();

    return (
        <>
            <List role="menu">
                <PermissionsGate scopes={[SCOPES.canAccessBookingsList]}>
                    <MenuListItem
                        icon={<LuggageOutlinedIcon fontSize="small" />}
                        label="Bookings"
                        href={BOOKINGS_PENDING}
                        selected={onPage(`${BOOKINGS}/*`)}
                    />
                </PermissionsGate>
                {/* Requests Menu Start*/}
                <PermissionsGate scopes={[SCOPES.canAccessRequestsList, SCOPES.canCreateRequest]}>
                    <ListItemButton
                        onClick={() => setRequestsOpen(!requestsOpen)}
                        className={onPage(`${REQUESTS}/*`) ? classes.activeMenu : ''}
                    >
                        <ListItemIcon>
                            <TravelExploreOutlined fontSize="small" />
                        </ListItemIcon>
                        <ListItemText primary="Requests" />
                        {requestsOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                </PermissionsGate>
                <PermissionsGate scopes={[SCOPES.canAccessRequestsList, SCOPES.canCreateRequest]}>
                    <Collapse
                        in={requestsOpen}
                        timeout="auto"
                        unmountOnExit
                        className={onPage(`${REQUESTS}/*`) ? classes.activeMenu : ''}
                    >
                        <List component="div" disablePadding>
                            <PermissionsGate scopes={[SCOPES.canCreateRequest]}>
                                <ListItem disablePadding onClick={() => navigate(CREATE_REQUEST)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PlaylistAddOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                            disableTypography
                                            className={onPage(CREATE_REQUEST) ? classes.activeSubMenu : ''}
                                            sx={{ fontSize: '15px' }}
                                            primary="New Request"
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </PermissionsGate>
                            <PermissionsGate scopes={[SCOPES.canAccessRequestsList]}>
                                <ListItem disablePadding onClick={() => navigate(REQUESTS_PENDING)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <PlaylistAddCheckOutlined fontSize="small" />
                                        </ListItemIcon>
                                        <ListItemText
                                            disableTypography
                                            className={
                                                onPage(`${REQUESTS}/*`) && !onPage(CREATE_REQUEST) ? classes.activeSubMenu : ''
                                            }
                                            sx={{ fontSize: '15px' }}
                                            primary="Requests List"
                                        />
                                    </ListItemButton>
                                </ListItem>
                            </PermissionsGate>
                        </List>
                    </Collapse>
                </PermissionsGate>
                {/* Requests Menu End*/}
                <PermissionsGate>
                    <MenuListItem
                        icon={<BusinessOutlinedIcon fontSize="small" />}
                        label="Accounts"
                        href={ACCOUNTS_HOTELS}
                        selected={onPage(`${ACCOUNTS}/*`)}
                    />
                </PermissionsGate>
                <PermissionsGate scopes={[SCOPES.canAccessUsers]}>
                    <MenuListItem
                        icon={<PeopleAltOutlinedIcon fontSize="small" />}
                        label="Users"
                        href={isHotelUser ? HOTEL_USERS_ACTIVE : PEOPLE_ACTIVE}
                        selected={onPage(`${isHotelUser ? '/hotel/users' : PEOPLE}/*`)}
                    />
                </PermissionsGate>
                {useFinancialReconcilliation && (
                    <>
                        <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                            <MenuListItem
                                icon={<ReceiptLongIcon fontSize="small" />}
                                label="Invoices"
                                href={INVOICES}
                                selected={onPage(`${INVOICES}/*`)}
                            />
                        </PermissionsGate>
                    </>
                )}
                {!isHotelUser && (
                    <MenuListItem
                        icon={<img src={GetEIcon} alt="get-e" style={{ width: '1rem' }} />}
                        label="Transfers portal"
                        href="#"
                        onClick={() => window.open(TRANSFER, '_blank')}
                    />
                )}
            </List>
        </>
    );
};

export default NavigationList;
