import { useQuery } from 'react-query';

import { BOOKING_COMMENTS } from '../../../constans/queryKeys';
import { listBookingComments } from '../../../services/booking';

export function useBookingComments(id: number, isEnabled: boolean) {
    const { data, isLoading, isError, isFetching, isFetched, refetch } = useQuery(
        [BOOKING_COMMENTS],
        () => listBookingComments(id),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
            enabled: isEnabled,
        }
    );

    return { data, isLoading, isError, isFetching, isFetched, refetch };
}
