import { useFlags } from 'launchdarkly-react-client-sdk';

import SimpleLink from '../../components/SimpleLink';
import { INVOICES } from '../../constans/urlPaths';
import { PageDefinition } from '../../createRoutes';
import Page from '../../layouts/Page';
import { SCOPES } from '../../permissions/permissionMaps';
import PermissionsGate from '../../permissions/PermissionsGate';
import Invoices from './Invoices';

export const invoices: PageDefinition = {
    path: INVOICES,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Invoices" link={INVOICES} />,
                    }}
                    isFullContent
                >
                    <Invoices />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};
