import { ComponentsProvider, FullScreenLoader, Notification, NotificationSeverity } from '@get-e/react-components';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license-pro';
import { FunctionComponent, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';

import { initAmplitude } from './amplitude/amplitude';
import { LocaleContext } from './context/LocaleContext';
import { NotificationContext, Severity } from './context/NotificationContext';
import useEffectAsync from './helpers/useEffectAsync';
import useLoaderDebounce from './helpers/useLoaderDebounce';
import { setLocale as setI18nLocale } from './i18n';
import ErrorBoundaryPage from './pages/errorBoundaryPage/ErrorBoundaryPage';
import Routes from './Routes';
import store from './store/store';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_LICENCE_KEY ?? '');

const App: FunctionComponent = () => {
    const queryClient = new QueryClient();
    const [localeLoaded, setLocaleLoaded] = useState(false);

    const [notification, setNotification] = useState<{
        message: string;
        color: NotificationSeverity;
    } | null>(null);

    useEffectAsync(async () => {
        await setI18nLocale('en-GB');
        setLocaleLoaded(true);
    }, []);

    const showLoader = useLoaderDebounce(!localeLoaded);

    if (!localeLoaded) {
        return showLoader ? <FullScreenLoader /> : null;
    }

    const showNotification = (message: string, severity?: Severity): void => {
        const color = (() => {
            switch (severity) {
                case Severity.Info:
                case undefined:
                    return 'info';
                case Severity.Error:
                    return 'error';
                default:
                    throw new Error('Unsupported severity');
            }
        })();

        setNotification({
            message,
            color,
        });
    };

    const notificationElement = (() => {
        if (notification === null) {
            return null;
        }

        return (
            <Notification severity={notification.color} onClose={() => setNotification(null)}>
                {notification.message}
            </Notification>
        );
    })();

    initAmplitude();

    return (
        <ErrorBoundary FallbackComponent={ErrorBoundaryPage}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <LocaleContext.Provider value={{ locale: 'en-GB' }}>
                            <ComponentsProvider>
                                <NotificationContext.Provider value={{ showNotification }}>
                                    {notificationElement}
                                    <Routes />
                                </NotificationContext.Provider>
                            </ComponentsProvider>
                        </LocaleContext.Provider>
                    </QueryClientProvider>
                </Provider>
            </LocalizationProvider>
        </ErrorBoundary>
    );
};

export default App;
