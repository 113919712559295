import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getDownloadInvoiceFileRoute } from '../../../services/routes';

const downloadOrPreviewInvoiceFile = async ({
    id,
    fileId,
    isDownloading,
    fileName,
}: {
    id: number;
    fileId: number;
    isDownloading?: boolean;
    fileName?: string;
}): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadInvoiceFileRoute(id, fileId), { responseType: 'blob' })
        .then(response => {
            if (isDownloading) {
                const url = window.URL.createObjectURL(new Blob([response.data]));

                // const fileType = response?.data?.type;
                // const fileExtension = typeToExtensionMap[fileType as keyof typeof typeToExtensionMap] || 'bin';

                downloadFile(url, `${fileName}`);
            }

            return response;
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadOrPreviewInvoiceFile;
