import apiClient from '../../../services/api';
import { EXPORT_FILES_INVOICES } from '../../../services/routes';
import { InvoiceVariables } from '../types';

const exportInvoiceFiles = async (variables: InvoiceVariables): Promise<unknown> => {
    const searchParams = new URLSearchParams({
        searchValue: variables?.search || '',
        fromDateLocal: variables?.fromDateLocal || '',
        toDateLocal: variables?.toDateLocal || '',
        page: ((variables?.page ?? 0) + 1)?.toString() || '',
        statuses: variables?.statuses?.map(el => el).join(',') || '',
        onlyCanceledBookings: variables.onlyCanceledBookings ? '1' : '0',
        onlyNotDownloaded: variables.onlyNotDownloaded ? '1' : '0',
        bookingChannelIds: variables?.bookingChannelIds?.map(el => el).join(',') || '',
    });

    const result = await apiClient.post(`${EXPORT_FILES_INVOICES}`, searchParams);

    return result;
};

export default exportInvoiceFiles;
