import { NoResultsDataGrid as NoResults } from '@get-e/react-components';
import { KeyboardAltOutlined } from '@mui/icons-material';
import { Card, CardContent, Grid } from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';

import { COLORS } from '../../../../constans/colors';
import { BookingOption } from '../api/types';
import { useBookingColumns } from '../hook/useBookingColumns';
import { SectionHeader } from './SectionHeader';

interface BookingOptionsDataGridProps {
    bookingOptions?: BookingOption[];
}

const BookingOptionsDataGrid = ({ bookingOptions }: BookingOptionsDataGridProps) => {
    const columns = useBookingColumns();

    return (
        <Grid
            item
            sx={{
                width: '100%',
            }}
        >
            <Card variant="outlined">
                <CardContent>
                    <SectionHeader title="Select bookings" />
                    <DataGridPro
                        disableColumnSelector
                        disableColumnMenu
                        disableColumnFilter
                        autoHeight
                        rows={bookingOptions ?? []}
                        columns={columns as GridColDef[]}
                        slots={{
                            noRowsOverlay: () => (
                                <NoResults
                                    icon={<KeyboardAltOutlined fontSize="medium" style={{ color: COLORS.SLATE_GREY }} />}
                                    text={'No results found.'}
                                />
                            ),
                        }}
                    />
                </CardContent>
            </Card>
        </Grid>
    );
};

export default BookingOptionsDataGrid;
