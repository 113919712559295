import BusinessIcon from '@mui/icons-material/Business';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';

import { COLORS } from '../../../../constans/colors';
import { useIsBackoffice } from '../../../../context/AuthenticatedUserContext';
import { getCheckDate } from '../../../../helpers/dateUtils';
import getDueInTime from '../../../../helpers/getDueInTime';
import { BookingStatus, BookingGuest } from '../../api/types';
import StatusButton from './StatusButton';

const useStyles = makeStyles({
    card: {
        border: `1px solid ${COLORS.DARK_GRAY}`,
        margin: '.75rem 0 .75rem 0',
        padding: '2rem',
    },
    searchingButton: {
        marginTop: '1rem',
    },
    hotelContainer: {
        display: 'flex',
        alignItems: 'flex-end',
    },
    userIcon: {
        marginRight: '0.5rem',
    },
    heading: {
        display: 'flex',
        flexDirection: 'row',
    },
    headingMobile: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginBottom: '1rem',
    },
    primaryHeading: {
        fontSize: '0.8rem',
        fontWeight: 'bold',
    },
    secondaryText: {
        color: COLORS.SLATE_GREY,
        display: 'inline',
        fontSize: '0.9rem',
    },
    durationText: {
        color: COLORS.SLATE_GREY,
        fontSize: '0.7rem',
    },
    headingDetails: {
        display: 'flex',
        alignItems: 'center',
    },
});

interface BookingCardProps {
    bookingId: number;
    status: BookingStatus;
    checkInDate: string;
    checkOutDate: string;
    hotelName: string;
    guests: BookingGuest[];
    customerName: string;
    units: number;
    timeToAction?: string;
    onClick: (event: React.MouseEvent<HTMLDivElement>, id: number) => void;
}

const BookingCompactCard = ({
    bookingId,
    status,
    checkInDate,
    checkOutDate,
    hotelName,
    guests,
    customerName,
    units,
    timeToAction,
    onClick,
}: BookingCardProps) => {
    const classes = useStyles();
    const isMobile = useMediaQuery('(max-width:600px)');
    const isBackoffice = useIsBackoffice();

    return (
        <Grid paddingBottom={1}>
            <Card
                variant="outlined"
                sx={{
                    ':hover': {
                        backgroundColor: COLORS.EXTRA_LIGHT_GRAY,
                        cursor: 'pointer',
                    },
                }}
            >
                <CardContent
                    onClick={evt => onClick(evt, bookingId)}
                    sx={{
                        padding: '0.2rem 0.5rem 0.2rem 0.5rem !important',
                    }}
                >
                    <div className={isMobile ? classes.headingMobile : classes.heading}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            <Grid item xs={isMobile ? 12 : isBackoffice ? 2 : 2.2}>
                                <Typography variant="h6" className={classes.primaryHeading}>
                                    {getCheckDate(checkInDate)} - {getCheckDate(checkOutDate)}
                                    <span className={classes.durationText}>&nbsp;({units} nights)</span>
                                </Typography>
                                {isBackoffice && timeToAction && (
                                    <Typography variant="h6" className={classes.primaryHeading}>
                                        {getDueInTime(timeToAction)}
                                    </Typography>
                                )}
                            </Grid>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            <Grid item xs={isMobile ? 12 : isBackoffice ? 4 : 5}>
                                <div className={classes.hotelContainer}>
                                    <HotelOutlinedIcon sx={{ fontSize: '1.2rem' }} className={classes.userIcon} />
                                    <Typography variant="body1" className={classes.secondaryText}>
                                        {hotelName}
                                    </Typography>
                                </div>
                            </Grid>
                            {/* eslint-disable-next-line no-nested-ternary */}
                            <Grid item xs={isMobile ? 12 : isBackoffice ? 2.3 : 2.8}>
                                <div className={classes.hotelContainer}>
                                    <PersonIcon sx={{ fontSize: '1.2rem' }} className={classes.userIcon} />
                                    <Typography variant="body1" className={classes.secondaryText}>
                                        {guests && `${guests[0].first_name} ${guests[0].last_name}`}
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item xs={isMobile ? 12 : 2} style={{ textAlign: 'center' }}>
                                <StatusButton variant={status}>{status}</StatusButton>
                            </Grid>
                            {isBackoffice && (
                                <Grid item xs={isMobile ? 12 : 1.7}>
                                    <div className={classes.hotelContainer}>
                                        <BusinessIcon sx={{ fontSize: '1.1rem' }} className={classes.userIcon} />
                                        <Typography variant="body1" sx={{ fontSize: '0.9rem' }}>
                                            {customerName}
                                        </Typography>
                                    </div>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default React.memo(BookingCompactCard);
