import { SecondaryButton } from '@get-e/react-components';
import { CloudDownload } from '@mui/icons-material';
import { useMutation } from 'react-query';

import downloadFile from '../../api/downloadFile';
import downloadFiles from '../../api/downloadFiles';

export const DownloadAllFiles = ({ id, invoiceFileName, fileId }: { id: string; invoiceFileName: string; fileId?: number }) => {
    const { mutate: downloadAllFilesMutation, isLoading } = useMutation(downloadFiles);
    const { mutate: downloadOneFileMutation, isLoading: isLoadingOneFile } = useMutation(downloadFile);

    const handleDownloadAllFiles = () => {
        if (fileId) {
            downloadOneFileMutation({ id, fileId, fileName: invoiceFileName });
        } else {
            downloadAllFilesMutation({ id, invoiceFileName });
        }
    };

    return (
        <SecondaryButton onClick={handleDownloadAllFiles} loading={isLoading || isLoadingOneFile}>
            <CloudDownload sx={{ fontSize: '1.5rem', marginRight: '.5rem' }} /> Download all
        </SecondaryButton>
    );
};
