import { MultipleSelect } from '@get-e/react-components';
import { Skeleton } from '@mui/material';
import { useTranslation } from 'react-i18next';

import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import { useBookingChannels } from '../uploadInvoice/api/useBookingChannels';

interface BookingChannelSelectProps {
    value: string[];
    onSetIds: (value: string[]) => void;
    required?: boolean;
    error?: InputError | null;
    defaultValue?: string[];
}

const BookingChannelSelect = ({ value, onSetIds, error = null, required, defaultValue }: BookingChannelSelectProps) => {
    const { t } = useTranslation();
    const { data: bookingChannels = [], isLoading: isLoadingBookingChannels, isFetching } = useBookingChannels();

    const BOOKING_CHANNELS = bookingChannels?.map(item => [item.id.toString(), item.name]) as unknown as Map<string, string>;

    return !isFetching ? (
        <MultipleSelect
            label="Booking channel"
            value={value}
            values={new Map(BOOKING_CHANNELS)}
            onSetIds={onSetIds}
            required={required}
            disabled={isLoadingBookingChannels}
            error={error !== null}
            helperText={getHelperText(error, t)}
            defaultSelectedValue={defaultValue}
        />
    ) : (
        <Skeleton variant="rectangular" width="100%" height={56} />
    );
};

export default BookingChannelSelect;
