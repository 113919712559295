import downloadFile from '../../../helpers/downloadFile';
import apiClient from '../../../services/api';
import { getDownloadFilesRoute } from '../../../services/routes';

const downloadFiles = async ({ id, invoiceFileName }: { id: string; invoiceFileName: string }): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadFilesRoute(id), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            // Pattern: name-of-the-hotel-yyyy-mm-dd-invoicenumber
            downloadFile(url, `${invoiceFileName}.zip`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadFiles;
