import { useQuery } from 'react-query';

import { CHECK_INVOICE_REFERENCE } from '../../../../constans/queryKeys';
import useDebounce from '../../../../helpers/useDebounce';
import apiClient from '../../../../services/api';
import { INVOICES } from '../../../../services/routes';
import { CheckInvoiceRefVariables } from './types';

export function useCheckInvoiceReference(variables: CheckInvoiceRefVariables) {
    const { hotelId, search, isEnabled } = variables;
    const debouncedSearchQuery = useDebounce(search, 400);

    return useQuery(
        [CHECK_INVOICE_REFERENCE, debouncedSearchQuery, hotelId, isEnabled],
        () => checkInvoiceRef({ search: debouncedSearchQuery, hotelId }),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            cacheTime: 500,
            enabled: Boolean(debouncedSearchQuery) && Boolean(hotelId) && isEnabled,
        }
    );
}

export const checkInvoiceRef = async (variables: CheckInvoiceRefVariables): Promise<boolean> => {
    const { hotelId, search } = variables;
    const searchParams = new URLSearchParams({ hotelId, invoiceReference: search }).toString();

    const response = await apiClient.get(`${INVOICES}/check-invoice-reference?${searchParams}`);

    return response.data?.isUnique as boolean;
};
