/* eslint-disable no-nested-ternary */
/* eslint-disable complexity */
import { LabeledData, PrimaryButton, SecondaryButton, Select, SelectOption, Spinner, TextField } from '@get-e/react-components';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';

import { COLORS } from '../../../constans/colors';
import { INVOICES } from '../../../constans/urlPaths';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { getPriceNumberWithDecimals } from '../../../helpers/getPriceNumberWithDecimals';
import getHelperText from '../../../helpers/validation/getHelperText';
import InputError from '../../../helpers/validation/InputError';
import isFilledString from '../../../helpers/validation/validators/isFilledString';
import { createCustomerCommment, updateInoviceStatus } from '../../../services/invoice';
import downloadInvoiceFile from '../api/downloadOrPreviewInvoiceFile';
import { DownloadAllFiles } from '../components/files/DownloadAllFiles';
import { FileItem } from '../components/files/FileItem';
import PreviewItem from '../components/files/PreviewItem';
import { StatusOption } from '../types';
import { STATUS_OPTION_CUSTOMER } from '../uploadInvoice/api/types';
import { useInvoice } from '../uploadInvoice/api/useInvoice';
import BookingOptionsDataGrid from '../uploadInvoice/components/BookingOptionsDataGrid';
import { SectionHeader } from '../uploadInvoice/components/SectionHeader';
import { getEnumKeyByValue, getInvoiceDownloadFileName } from '../uploadInvoice/helpers';

const useStyles = makeStyles(() => ({
    label: {
        fontWeight: '500 !important',
        marginRight: '0 !important',
    },
    mr1: {
        marginRight: '1rem',
    },
    helperText: {
        '& .MuiFormHelperText-root': { color: `${COLORS.RED} !important` },
    },
    helperTextBlue: {
        '& .MuiFormHelperText-root': { color: `${COLORS.BLUE_TEXT} !important` },
    },
}));

const CustomerView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const { id = '' } = useParams();
    const { t } = useTranslation();
    const { showNotification } = useNotificationContext();
    const [status, setStatus] = useState('');
    const [statusError, setStatusError] = useState<InputError | null>(null);
    const [isReadyForReview, setIsReadyForReview] = useState(false);
    const [currentFileId, setCurrentFileId] = useState<number | null>(null);
    const [comment, setComment] = useState('');

    const [previewData, setPreviewData] = useState<{
        fileUrl: string;
        fileName: string;
        fileType: string;
    } | null>(null);

    const { data: invoiceData, isLoading } = useInvoice(parseInt(id, 10));

    useEffect(() => {
        if (!invoiceData || isLoading) {
            return;
        }

        previewInvoiceFileMutation({ id: parseInt(id, 10), fileId: invoiceData.files.at(-1)?.id || 0 });
        setIsReadyForReview(invoiceData?.status !== (StatusOption.APPROVED || StatusOption.REJECTED));
        setStatus(
            invoiceData?.status === StatusOption.UNDER_REVIEW
                ? ''
                : STATUS_OPTION_CUSTOMER[invoiceData?.status as keyof typeof STATUS_OPTION_CUSTOMER]
        );
        setCurrentFileId(invoiceData.files.at(-1)?.id || null);
        setComment(invoiceData?.customerComment || '');
    }, [invoiceData]);

    const { mutate: updateStatusMutation, isLoading: isLoadingUpdate } = useMutation(updateInoviceStatus, {
        onSuccess: () => {
            showNotification('Status successfully updated', Severity.Info);
            navigate(INVOICES);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const { mutate: previewInvoiceFileMutation, isLoading: isLoadingPreview } = useMutation(downloadInvoiceFile, {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onSuccess: (data: any) => {
            const fileBlob = data?.data;
            const fileUrl = URL.createObjectURL(fileBlob);
            const fileType = data?.data?.type;

            setPreviewData({
                fileUrl,
                fileType,
                fileName: 'invoice-file',
            });
        },
    });

    const { mutate: createCommentMutation, isLoading: isLoadingCommentUpdate } = useMutation(createCustomerCommment, {
        onSuccess: () => {
            showNotification('Comment successfully uploaded.', Severity.Info);
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const handleShowEditedFile = (fileId: number) => {
        previewInvoiceFileMutation({ id: parseInt(id, 10), fileId });
        setCurrentFileId(fileId);
    };

    const handleSubmit = () => {
        const validatedReference = isFilledString(status, InputError.Required);

        setStatusError(validatedReference.isValid ? null : validatedReference.error);

        if (validatedReference.isValid) {
            updateStatusMutation({
                id,
                payload: {
                    status: getEnumKeyByValue(status) || '',
                },
            });
        }
    };

    const handleSubmitComment = () => {
        if (comment) {
            createCommentMutation({ id, content: comment });
        }
    };

    const handleBack = () => navigate(-1);

    if (isLoading && !invoiceData) {
        return null;
    }

    return (
        <Grid container spacing={4}>
            <Grid item xs={12} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography
                    sx={{
                        color: COLORS.BLUE,
                        fontSize: '1.5rem',
                        fontWeight: 700,
                    }}
                >
                    Invoice {id}
                </Typography>
                <Box>
                    <SecondaryButton onClick={handleBack}>Back</SecondaryButton>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <SectionHeader title="Invoices" />
                        {previewData && (
                            <PreviewItem
                                fileUrl={previewData.fileUrl}
                                fileName={previewData.fileName}
                                fileType={previewData.fileType}
                            />
                        )}
                        <Box marginTop={'1rem'}>
                            {invoiceData?.files?.map((file, index) => (
                                <FileItem
                                    key={index}
                                    file={file}
                                    handlePreview={() => handleShowEditedFile(file.id)}
                                    isSelected={file.id === currentFileId}
                                />
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                marginBottom: '1rem',
                                gap: '2rem',
                            }}
                        >
                            {invoiceData?.files && invoiceData?.files?.length > 0 && id && (
                                <DownloadAllFiles
                                    id={id}
                                    invoiceFileName={getInvoiceDownloadFileName(
                                        invoiceData?.hotel?.name || '',
                                        dayjs(invoiceData?.invoiceDateLocal),
                                        id
                                    )}
                                    fileId={invoiceData?.files?.length === 1 ? invoiceData?.files?.[0]?.id : undefined}
                                />
                            )}
                            {isLoadingPreview && <Spinner size={12} />}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={6}>
                <Card variant="outlined">
                    <CardContent>
                        <SectionHeader title="Invoice details" sx={{ marginBottom: '.5rem !important' }} />
                        <LabeledData
                            label="Booking channel:"
                            labelColor={COLORS.BLACK}
                            data={invoiceData?.bookingChannels?.map(el => el.name)?.join(', ')}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <LabeledData
                            label="Invoice reference:"
                            labelColor={COLORS.BLACK}
                            data={invoiceData?.invoiceReference}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <LabeledData
                            label="Invoice date:"
                            labelColor={COLORS.BLACK}
                            data={dayjs(invoiceData?.invoiceDateLocal).format(DATE_FORMATS['DD MMM YYYY'])}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <LabeledData
                            label="Due date:"
                            labelColor={COLORS.BLACK}
                            data={dayjs(invoiceData?.dueDateLocal).format(DATE_FORMATS['DD MMM YYYY'])}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <SectionHeader title="Amount" sx={{ marginBottom: '.5rem !important', marginTop: '1rem !important' }} />
                        <LabeledData
                            label="Currency:"
                            labelColor={COLORS.BLACK}
                            data={`${invoiceData?.currency?.iso} - ${invoiceData?.currency?.name}`}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <LabeledData
                            label="Invoice total amount:"
                            labelColor={COLORS.BLACK}
                            data={getPriceNumberWithDecimals(invoiceData?.totalAmount)}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="0"
                        />
                        <SectionHeader title="Status" sx={{ marginBottom: '.5rem !important', marginTop: '1rem !important' }} />
                        <LabeledData
                            label="Get-e comments:"
                            labelColor={COLORS.BLACK}
                            data={invoiceData?.comments}
                            ignoreColumns
                            labelClassName={classes.label}
                            paddingBottom="1rem"
                        />
                        <div className={isReadyForReview ? classes.helperText : classes.helperTextBlue}>
                            <Select
                                label="Booking status"
                                value={status}
                                onChangeValue={newValue => {
                                    setStatus(newValue);
                                    setStatusError(null);
                                }}
                                required
                                disabled={!isReadyForReview}
                                error={statusError !== null}
                                helperText={
                                    statusError !== null
                                        ? getHelperText(statusError, t)
                                        : isReadyForReview
                                          ? 'Once approved, the invoice cannot be changed.'
                                          : 'The invoice has been approved and can’t be changed'
                                }
                            >
                                {Object.keys(STATUS_OPTION_CUSTOMER).map(repeat => (
                                    <SelectOption
                                        key={repeat}
                                        value={STATUS_OPTION_CUSTOMER[repeat as keyof typeof STATUS_OPTION_CUSTOMER]}
                                    >
                                        {STATUS_OPTION_CUSTOMER[repeat as keyof typeof STATUS_OPTION_CUSTOMER]}
                                    </SelectOption>
                                ))}{' '}
                            </Select>
                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {isReadyForReview && (
                                <PrimaryButton onClick={handleSubmit} className={classes.mr1} loading={isLoadingUpdate}>
                                    Update
                                </PrimaryButton>
                            )}
                        </Box>

                        <LabeledData label="Leave your comment" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                        <TextField
                            label="Comment"
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            placeholder="Add any comments here"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                            <PrimaryButton onClick={handleSubmitComment} loading={isLoadingCommentUpdate || isLoadingUpdate}>
                                Update comment
                            </PrimaryButton>
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            <BookingOptionsDataGrid bookingOptions={invoiceData?.bookings} />
        </Grid>
    );
};

export default CustomerView;
