import dayjs from 'dayjs';

import { DATE_FORMATS } from './dateFormats';

export const getStartEndDate = (startDateLocal: string, endDateLocal: string) => {
    const isSameDate = startDateLocal === endDateLocal;

    const formattedStartDate = dayjs(startDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

    const formattedEndDate = dayjs(endDateLocal).format(DATE_FORMATS['DD MMM YYYY']);

    return isSameDate ? formattedStartDate : `${formattedStartDate} - ${formattedEndDate}`;
};
