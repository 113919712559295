import { Box, Typography } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid';
import { useMemo } from 'react';

import { COLORS } from '../../../../constans/colors';
import { BOOKINGS } from '../../../../constans/urlPaths';
import { getPriceNumberWithDecimals } from '../../../../helpers/getPriceNumberWithDecimals';
import { getStartEndDate } from '../../../../helpers/getStartEndDate';
import StatusButton from '../../../reservations/components/StatusButton';
import { mapToBookingStatus } from '../helpers';

const COLUMN_WIDTH = 200;

export const useBookingColumns = () => {
    return useMemo(
        () => [
            {
                field: 'bookingNumber',
                headerName: 'Booking number',
                renderCell: (params: GridRowParams) => {
                    return (
                        <Box
                            sx={{ color: COLORS.BLUE_TEXT, textDecoration: 'underline', cursor: 'pointer' }}
                            onClick={event => {
                                event.stopPropagation();
                                window.open(`${BOOKINGS}/${params.row?.id}`, '_blank');
                            }}
                        >
                            {params.row.id}
                        </Box>
                    );
                },
            },
            {
                field: 'confirmationNumber',
                headerName: 'Confirmation number',
                minWidth: COLUMN_WIDTH,
                flex: 1,
            },
            {
                field: 'checkInOut',
                headerName: 'Check-in/out',
                minWidth: 250,
                renderCell: (params: GridRowParams) => {
                    return <>{getStartEndDate(params.row.checkInDateLocal, params.row.checkOutDateLocal)}</>;
                },
            },
            {
                field: 'guest',
                headerName: 'Name',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return (
                        <>
                            <Typography component="span" color={COLORS.SLATE_GREY}>
                                {params.row.guest.employeeNumber}
                            </Typography>{' '}
                            {params.row.guest.name}
                        </>
                    );
                },
            },
            {
                field: 'amount',
                headerName: 'Amount',
                minWidth: 200,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return (
                        <>
                            {params.row.currencyIso} {getPriceNumberWithDecimals(params.row.amount)}
                        </>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                width: 180,
                renderCell: (params: GridRowParams) => {
                    return (
                        <StatusButton variant={mapToBookingStatus(params.row.status)}>
                            {mapToBookingStatus(params.row.status) as string}
                        </StatusButton>
                    );
                },
            },
        ],
        []
    );
};

export const mockBookingData = [
    {
        id: 1,
        bookingNumber: 'BN123456',
        confirmationNumber: 'CN654321',
        checkInDateLocal: '2024-12-20',
        checkOutDateLocal: '2024-12-25',
        guest: {
            name: 'Maxie Zemlak',
            employeeNumber: 'GGWWP',
        },
        currencyIso: 'USD',
        totalAmount: 1500.0,
        totalRoomNights: 5,
        status: 'Confirmed',
    },
    {
        id: 2,
        bookingNumber: 'BN789012',
        confirmationNumber: 'CN210987',
        checkInDateLocal: '2024-12-17',
        checkOutDateLocal: '2024-12-17',
        guest: {
            name: 'Maxie Zemlak',
            employeeNumber: 'GGWWP',
        },
        currencyIso: 'EUR',
        totalAmount: 200.0,
        totalRoomNights: 1,
        status: 'Cancelled',
    },
    {
        id: 3,
        bookingNumber: 'BN345678',
        confirmationNumber: 'CN876543',
        checkInDateLocal: '2024-12-24',
        checkOutDateLocal: '2024-12-31',
        guest: {
            name: 'Maxie Zemlak',
            employeeNumber: 'GGWWP',
        },
        currencyIso: 'GBP',
        totalAmount: 2500.0,
        totalRoomNights: 7,
        status: 'Pending',
    },
    {
        id: 4,
        bookingNumber: 'BN901234',
        confirmationNumber: 'CN432109',
        checkInDateLocal: '2024-11-15',
        checkOutDateLocal: '2024-11-20',
        guest: {
            name: 'Maxie Zemlak',
            employeeNumber: 'GGWWP',
        },
        currencyIso: 'AUD',
        totalAmount: 1200.0,
        totalRoomNights: 5,
        status: 'Confirmed',
    },
    {
        id: 5,
        bookingNumber: 'BN567890',
        confirmationNumber: 'CN098765',
        checkInDateLocal: '2024-12-10',
        checkOutDateLocal: '2024-12-15',
        guest: {
            name: 'Maxie Zemlak',
            employeeNumber: 'GGWWP',
        },
        currencyIso: 'CAD',
        totalAmount: 1800.0,
        totalRoomNights: 5,
        status: 'Confirmed',
    },
];
