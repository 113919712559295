import { useFlags } from 'launchdarkly-react-client-sdk';

import SimpleLink from '../../../components/SimpleLink';
import { LOCAL_STORAGE_KEYS } from '../../../constans/localStorageKeys';
import { CUSTOMER_INVOICE, EDIT_INVOICE, UPLOAD_INVOICE } from '../../../constans/urlPaths';
import { PageDefinition } from '../../../createRoutes';
import Page from '../../../layouts/Page';
import { SCOPES } from '../../../permissions/permissionMaps';
import PermissionsGate from '../../../permissions/PermissionsGate';
import CustomerView from '../customerView/CustomerView';
import UploadInvoice from './UploadInvoice';

export const uploadInvoice: PageDefinition = {
    path: UPLOAD_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Upload Invoice" link={UPLOAD_INVOICE} />,
                    }}
                    isFullContent
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

export const editInvoice: PageDefinition = {
    path: EDIT_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label="Edit Invoice" link={EDIT_INVOICE} />,
                    }}
                    isFullContent
                >
                    <UploadInvoice />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};

export const viewCustomerInvoice: PageDefinition = {
    path: CUSTOMER_INVOICE,
    Component() {
        const { useFinancialReconcilliation } = useFlags();
        const invoiceNumber = window.localStorage.getItem(LOCAL_STORAGE_KEYS.INOVICE_EDITED) ?? '';

        return useFinancialReconcilliation ? (
            <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                <Page
                    breadcrumbs={{
                        name: <SimpleLink label={`Invoice ${invoiceNumber}`} link={CUSTOMER_INVOICE} />,
                    }}
                    isFullContent
                >
                    <CustomerView />
                </Page>
            </PermissionsGate>
        ) : null;
    },
};
