/* eslint-disable @typescript-eslint/no-explicit-any */
export interface UploadInvoiceRequest {
    hotelId: string;
    customerId: string;
    currencyId: string;
    invoiceReference: string;
    invoiceDateLocal: string;
    dueDateLocal: string;
    totalAmount: number | string;
    comments: string;
    files: Blob[];
    status: string;
    bookings: any;
    bookingChannelIds: string[] | string;
    id?: string;
}

export enum STATUS_OPTION {
    UNDER_REVIEW = 'Under review',
    READY_FOR_APPROVAL = 'Ready for approval',
    DISPUTED = 'Disputed',
    REJECTED = 'Rejected',
    APPROVED = 'Approved',
}

export enum STATUS_OPTION_CUSTOMER {
    REJECTED = 'Rejected',
    APPROVED = 'Approved',
    DISPUTED = 'Disputed',
}

interface Guest {
    name: string;
    employeeNumber: string;
}

export interface BookingOption {
    id: number;
    confirmationNumber: string;
    checkInDateLocal: string;
    checkOutDateLocal: string;
    guest: Guest;
    status: string;
    // coming from response
    amount?: number | string;
    financeInvoiceBookings?: number[];
}

export interface Booking {
    id: number;
    amount: string | null;
}

export interface CheckInvoiceRefVariables {
    hotelId: string;
    search: string;
    isEnabled?: boolean;
}
