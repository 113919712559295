import { DateRange } from '@mui/x-date-pickers-pro';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs, { Dayjs } from 'dayjs';

export interface DateRangeState {
    value: DateRange<Dayjs>;
}

const now = new Date();

const initialState: DateRangeState = {
    value: [
        dayjs(new Date(now.getFullYear(), now.getMonth(), now.getDate())),
        dayjs(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 30, 23, 59, 0, 0)),
    ],
};

export const dateSlice = createSlice({
    name: 'dateRangeFilter',
    initialState,
    reducers: {
        setDateRangeFilter: (state: DateRangeState, action: PayloadAction<DateRange<Dayjs>>) => {
            state.value = action.payload;
        },
    },
});

export const { setDateRangeFilter } = dateSlice.actions;

export default dateSlice.reducer;
