import { Box, Grid, Typography } from '@mui/material';

interface PreviewItemProps {
    fileUrl: string;
    fileName: string;
    fileType: string;
}

const PreviewItem = ({ fileUrl, fileName, fileType }: PreviewItemProps) => {
    const renderPreview = () => {
        if (fileType.includes('image')) {
            return <img src={fileUrl} alt={fileName} style={{ width: '100%', height: 'auto', objectFit: 'contain' }} />;
        } else if (fileType.includes('pdf')) {
            return <embed src={fileUrl} width="100%" height="100%" type="application/pdf" />;
        }

        return <Typography>No preview available for this file type.</Typography>;
    };

    return (
        <Grid item xs={12} sx={{ height: theme => theme.spacing(75) }}>
            <Box sx={{ height: '100%', overflow: 'hidden', position: 'relative' }}>{renderPreview()}</Box>
        </Grid>
    );
};

export default PreviewItem;
