/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-nested-ternary */
import { Box, IconButton, Tooltip } from '@mui/material';
import { GridRowParams } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { COLORS } from '../../../constans/colors';
import { BOOKINGS } from '../../../constans/urlPaths';
import { getPriceNumberWithDecimals } from '../../../helpers/getPriceNumberWithDecimals';
import { getStartEndDate } from '../../../helpers/getStartEndDate';
import { ReactComponent as Downloaded } from '../../../images/downloaded.svg';
import { ReactComponent as NotDownloaded } from '../../../images/notDownloaded.svg';
import { StatusOption } from '../types';
import { StatusComponent } from '../uploadInvoice/components/StatusComponent';
import { getInvoiceDownloadFileName } from '../uploadInvoice/helpers';

const COLUMN_WIDTH = 200;

export const useInvoicesColumns = ({
    handleDownloadClick,
}: {
    handleDownloadClick: (event: any, id: number, invoiceFileName: string) => void;
}) => {
    const navigate = useNavigate();

    return useMemo(
        () => [
            {
                field: 'invoiceReference',
                headerName: 'Invoice number',
                minWidth: 150,
                flex: 1,
            },
            {
                field: 'bookingNumber',
                headerName: 'Booking number',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    const validBooking = params.row.foundBookings?.[0] ? params.row.foundBookings?.[0] : params.row.bookings?.[0];

                    const bookingComponent = (
                        <Box>
                            <>{params.row.foundBookings?.[0] && `${params.row.bookingCount} bookings`}</>
                            <Box
                                sx={{ color: COLORS.BLUE_TEXT, textDecoration: 'underline', cursor: 'pointer' }}
                                onClick={event => {
                                    event.stopPropagation();
                                    navigate(`${BOOKINGS}/${validBooking?.id}`, {
                                        state: validBooking?.id,
                                    });
                                }}
                            >
                                <span style={{ background: params.row.foundBookings?.[0] ? COLORS.YELLOW : 'none' }}>
                                    {validBooking?.id} {validBooking?.isCancelled && 'Cancelled'}
                                </span>
                            </Box>
                        </Box>
                    );

                    return (
                        <>
                            {params.row.bookingCount > 1 && !params.row?.foundBookings?.[0]
                                ? `${params.row.bookingCount} bookings`
                                : bookingComponent}
                        </>
                    );
                },
            },
            {
                field: 'crew',
                headerName: 'Crew',
                minWidth: 140,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    const validBooking = params.row.foundBookings?.[0] ? params.row.foundBookings?.[0] : params.row.bookings?.[0];

                    return <>{params.row.bookingCount > 1 ? '-' : validBooking?.guests?.[0]?.employeeNumber}</>;
                },
            },
            {
                field: 'hotelName',
                headerName: 'Hotel',
                width: 200,
                renderCell: (params: GridRowParams) => {
                    return <>{params.row.hotel.name}</>;
                },
            },
            {
                field: 'bookingChannel',
                headerName: 'Booking channel',
                minWidth: 250,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return <>{params.row.bookingChannels?.map((el: any) => el?.name)?.join(' , ')}</>;
                },
            },
            {
                field: 'date',
                headerName: 'Invoice date',
                minWidth: COLUMN_WIDTH,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return <>{getStartEndDate(params.row.invoiceDateLocal, params.row.dueDateLocal)}</>;
                },
            },
            {
                field: 'totalAmount',
                headerName: 'Invoice Amount',
                minWidth: 140,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    return (
                        <>
                            {params.row.currency?.iso} {getPriceNumberWithDecimals(params.row.totalAmount)}
                        </>
                    );
                },
            },
            {
                field: 'status',
                headerName: 'Status',
                minWidth: 130,
                flex: 1,
                renderCell: (params: GridRowParams) => {
                    const invoiceName = getInvoiceDownloadFileName(
                        params?.row?.hotel?.name || '',
                        params.row.invoiceDateLocal,
                        params?.row?.id
                    );

                    const isIconVisible =
                        params.row.status === StatusOption.APPROVED ? (
                            params.row.isCustomerDownloadedFiles ? (
                                <Tooltip title="Download invoice again" arrow>
                                    <Downloaded />
                                </Tooltip>
                            ) : (
                                <Tooltip title="Download invoice" arrow>
                                    <NotDownloaded />
                                </Tooltip>
                            )
                        ) : null;

                    return (
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', padding: '.5rem' }}>
                            <IconButton onClick={event => handleDownloadClick(event, params.row.id, invoiceName)}>
                                {isIconVisible}
                            </IconButton>

                            <div style={{ marginLeft: '.5rem' }}>
                                <StatusComponent status={params.row.status} />
                            </div>
                        </Box>
                    );
                },
            },
        ],
        []
    );
};
