import dayjs, { Dayjs } from 'dayjs';

import { DATE_FORMATS } from '../../../../helpers/dateFormats';
import { BookingStatus } from '../../../reservations/api/types';
import { STATUS_OPTION } from '../api/types';

export function mapToBookingStatus(status: string): BookingStatus | undefined {
    switch (status) {
        case 'SEARCHING_ROOMS':
            return BookingStatus.SEARCHING_ROOMS;
        case 'BOOKING_PENDING':
            return BookingStatus.BOOKING_PENDING;
        case 'BOOKING_UPDATE_PENDING':
            return BookingStatus.BOOKING_UPDATE_PENDING;
        case 'PROCESS_CANCELLATION':
            return BookingStatus.PROCESS_CANCELLATION;
        case 'BOOKING_CONFIRMED':
            return BookingStatus.BOOKING_CONFIRMED;
        case 'BOOKING_CANCELLED':
            return BookingStatus.BOOKING_CANCELLED;
        case 'NOT_AVAILABLE':
            return BookingStatus.NOT_AVAILABLE;
        case 'COMPLETED':
            return BookingStatus.COMPLETED;
        default:
            return undefined;
    }
}

export const isThisMonthRange = (dateRange: [Dayjs | null, Dayjs | null]): boolean => {
    if (!dateRange || !dateRange[0] || !dateRange[1]) {
        return false;
    }

    const [start, end] = dateRange;

    return start.isSame(dayjs().startOf('month'), 'day') && end.isSame(dayjs().endOf('month'), 'day');
};

export const getEnumKeyByValue = (value: string): keyof typeof STATUS_OPTION | undefined => {
    return Object.keys(STATUS_OPTION).find(key => STATUS_OPTION[key as keyof typeof STATUS_OPTION] === value) as
        | keyof typeof STATUS_OPTION
        | undefined;
};

export const getInvoiceDownloadFileName = (hotelName: string, invoiceDate: Dayjs | null, id: string) => {
    // Pattern: name-of-the-hotel-yyyy-mm-dd-invoicenumber
    return `${hotelName}-${dayjs(invoiceDate)?.format(DATE_FORMATS['YYYY-MM-DD'])}-${id}`;
};

export const DEFAULT_AIR_ATLANTA = {
    id: 20,
    name: 'Air Atlanta Icelandic',
};
