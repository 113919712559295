/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { PrimaryButton, SecondaryButton, Spinner, TextField } from '@get-e/react-components';
import { SyncLockSharp, SyncOutlined, TitleOutlined } from '@mui/icons-material';
import LocalCafeOutlinedIcon from '@mui/icons-material/LocalCafeOutlined';
import LocalParkingOutlinedIcon from '@mui/icons-material/LocalParkingOutlined';
import { Box, Card, CardContent, Grid, Typography, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { logAmplitudeEvent } from '../../../amplitude/amplitude';
import { BUTTON_SINGLE_BOOKING_EDIT, SCREEN_SINGLE_BOOKING } from '../../../amplitude/amplKeys';
import GoogleMap, { Point } from '../../../components/GoogleMap';
import { InvoicedTooltip } from '../../../components/InvoicedTooltip';
import LabeledData from '../../../components/LabeledData';
import PageTitle from '../../../components/PageTitle';
import { COLORS } from '../../../constans/colors';
import { EDIT_BOOKING_BASE_URL, UPLOAD_INVOICE } from '../../../constans/urlPaths';
import { useIsBackoffice } from '../../../context/AuthenticatedUserContext';
import { Severity, useNotificationContext } from '../../../context/NotificationContext';
import { DATE_FORMATS } from '../../../helpers/dateFormats';
import { getFormattedDateTimeString } from '../../../helpers/dateUtils';
import { calculateBookingPrice } from '../../../helpers/finance';
import { DEFAULT_COORDINATES } from '../../../helpers/maps/googleApi';
import { TIME_FORMATS } from '../../../helpers/timeFormats';
import { SCOPES } from '../../../permissions/permissionMaps';
import PermissionsGate from '../../../permissions/PermissionsGate';
import { createBookingCommment } from '../../../services/booking';
import { BookingOption } from '../../invoices/uploadInvoice/api/types';
import { Benefits, BookingCustomField } from '../api/types';
import { useBooking } from '../api/useBooking';
import { useBookingComments } from '../api/useBookingComments';
import StatusButton from '../components/StatusButton';
import ChangeLogTable from './changeLogs/ChangeLogTable';
import TransactionTable from './finance/TransactionTable';

const useStyles = makeStyles({
    header: {
        display: 'flex',
    },
    map: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 !important',
    },
    bookingStatus: {
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        marginRight: '1rem',
        fontWeight: '700',
    },
    buttonCancel: { marginLeft: '1rem' },
    addReservationButton: {
        width: '120px',
    },
    confirmCancelRequestButton: {
        background: COLORS.RED,
    },
    headerTitle: {
        margin: '1rem 0rem',
    },
    reservationStatus: {
        lineHeight: 'unset',
    },
    editButton: {
        width: '120px',
    },
    invoiceButton: {
        marginRight: '1rem',
    },
    commentBox: {
        padding: '.5rem',
        backgroundColor: COLORS.LIGHT_GRAY,
        marginBottom: '1rem',
    },
});

const ViewBooking = () => {
    const classes = useStyles();
    const { id = '' } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const isMobile = useMediaQuery('(max-width:600px)');
    const { data, isFetching } = useBooking(parseInt(id, 10));
    const isBackoffice = useIsBackoffice();

    const {
        data: bookingComments,
        isLoading: isLoadingComments,
        refetch: refetchComments,
    } = useBookingComments(parseInt(id, 10), isBackoffice);

    const { useFinancialReconcilliation } = useFlags();
    const [comment, setComment] = useState('');
    const { showNotification } = useNotificationContext();

    useEffect(() => {
        logAmplitudeEvent(SCREEN_SINGLE_BOOKING);
    }, []);

    useEffect(() => {
        if (!data || !bookingComments || isLoadingComments) {
            return;
        }

        location.state = {
            id: data.id,
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const guest = useMemo(() => {
        return data?.bookingGuests[0];
    }, [data?.bookingGuests]);

    const benefits = useMemo(() => {
        return data?.benefits?.map(benefit => benefit.name);
    }, [data?.benefits]);

    const includeBreakfast = useMemo(
        () => (benefits?.includes(Benefits.INCLUDE_BREAKFAST) ? 'Breakfast Required' : 'Breakfast Not required'),
        [benefits]
    );

    const includeParking = useMemo(
        () => (benefits?.includes(Benefits.INCLUDE_PARKING) ? 'Parking Required' : 'Parking Not required'),
        [benefits]
    );

    const points = useMemo(() => {
        return [
            {
                latitude: parseFloat(data?.hotelLatitude ?? DEFAULT_COORDINATES.lat.toString()),
                longitude: parseFloat(data?.hotelLongitude ?? DEFAULT_COORDINATES.long.toString()),
            } as Point,
        ];
    }, [data]);

    const { mutate: createCommentMutation, isLoading: isLoadingUpdate } = useMutation(createBookingCommment, {
        onSuccess: async () => {
            showNotification('Comment successfully uploaded.', Severity.Info);
            await refetchComments();
            setComment('');
        },
        onError: (error: AxiosError<{ message: string }>) => {
            showNotification(error?.response?.data?.message || 'Something went wrong', Severity.Error);
        },
    });

    const handleEdit = () => {
        navigate(`${EDIT_BOOKING_BASE_URL}${id}`, {
            state: {
                requestNumber: data?.id,
            },
        });
        logAmplitudeEvent(BUTTON_SINGLE_BOOKING_EDIT);
    };

    const handleUploadInvoice = () => {
        navigate(UPLOAD_INVOICE, {
            state: {
                customer: {
                    id: data?.customerId,
                    name: data?.customerName,
                },
                hotel: {
                    id: data?.hotelId,
                    name: data?.hotelName,
                },
                invoiceDateLocal: data?.checkOutDateLocal,
                bookingOption: {
                    id: data?.id,
                    checkInDateLocal: data?.checkInDateLocal,
                    checkOutDateLocal: data?.checkOutDateLocal,
                    confirmationNumber: data?.bookingNumber,
                    status: data?.status,
                    guest: {
                        name: '',
                        employeeNumber: '',
                    },
                } as BookingOption,
            },
        });
    };

    const handleSubmitComment = () => {
        if (comment) {
            createCommentMutation({ id: parseInt(id, 10), content: comment });
        }
    };

    return (
        <>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} alignItems="center">
                {data?.customFields.find(
                    (field: BookingCustomField) => field.key === 'is-training-booking' && field.value === '1'
                ) && (
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                        <TitleOutlined fontSize="small" sx={{ display: 'inline' }}></TitleOutlined>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{
                                            paddingTop: '0',
                                            paddingLeft: '0.75rem !important',
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '1rem', display: 'inline' }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Training Booking
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {data?.isAutomated && (
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                        <SyncOutlined fontSize="small" sx={{ display: 'inline' }}></SyncOutlined>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{
                                            paddingTop: '0',
                                            paddingLeft: '0.75rem !important',
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '1rem', display: 'inline' }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Automated Booking
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {data?.isIgnoredBySynchronizer && (
                    <Grid item xs={isMobile ? 12 : 3}>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                            <Grid item xs={12}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={2} sx={{ paddingTop: '0' }}>
                                        <SyncLockSharp fontSize="small" sx={{ display: 'inline' }}></SyncLockSharp>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={9}
                                        sx={{
                                            paddingTop: '0',
                                            paddingLeft: '0.75rem !important',
                                        }}
                                    >
                                        <Typography
                                            sx={{ fontSize: '1rem', display: 'inline' }}
                                            color="text.secondary"
                                            gutterBottom
                                        >
                                            Ignored by Synchronizer
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>

            {/* Status | Hotel Name | Edit Button*/}
            <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                alignItems="center"
                sx={{ marginBottom: '1.5rem' }}
            >
                <Grid item xs={isMobile ? 4 : 2}>
                    {data?.status && (
                        <StatusButton className={classes.bookingStatus} variant={data?.status}>
                            {data?.status}
                        </StatusButton>
                    )}
                </Grid>

                {!isMobile && (
                    <Grid item xs={isMobile ? 8 : 5} display="flex" alignItems="center">
                        <PageTitle title={data?.hotelName} className={classes.headerTitle} />
                        {useFinancialReconcilliation && (
                            <Box sx={{ marginLeft: '1rem', marginBottom: '.5rem' }}>
                                {data?.financeInvoiceBookings && data?.financeInvoiceBookings?.length > 0 && (
                                    <InvoicedTooltip invoiceIds={data?.financeInvoiceBookings} iconSize={'1.5rem'} />
                                )}
                            </Box>
                        )}
                    </Grid>
                )}

                <Grid item xs={isMobile ? 8 : 5} textAlign={'right'}>
                    {isBackoffice && useFinancialReconcilliation && (
                        <PermissionsGate scopes={[SCOPES.canAccessFinancial]}>
                            <SecondaryButton onClick={handleUploadInvoice} className={classes.invoiceButton}>
                                Upload invoice
                            </SecondaryButton>
                        </PermissionsGate>
                    )}
                    <PermissionsGate scopes={[SCOPES.canEditBooking]}>
                        <SecondaryButton onClick={handleEdit} className={classes.editButton}>
                            Edit
                        </SecondaryButton>
                    </PermissionsGate>
                </Grid>
            </Grid>

            {/* Map and Address */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={50} />
                            </Grid>
                        )}
                        {!isFetching && (
                            <CardContent
                                sx={{
                                    paddingBottom: '0.5rem!important',
                                    paddingTop: '1rem!important',
                                }}
                            >
                                <LabeledData label="Hotel Name" data={data?.hotelName} />
                                <LabeledData label="Address" data={data?.hotelAddressLine} />

                                <LabeledData label="Country" data={data?.hotelCountryName} />
                                <LabeledData
                                    label="Check-in"
                                    data={dayjs(data?.hotelStandardCheckIn, 'HH:mm:ss').format('HH:mm')}
                                />
                                <LabeledData
                                    label="Check-out"
                                    data={dayjs(data?.hotelStandardCheckOut, 'HH:mm:ss').format('HH:mm')}
                                />
                            </CardContent>
                        )}
                    </Card>
                </Grid>
                <Grid item xs={isMobile ? 12 : 6}>
                    <Card
                        variant="outlined"
                        sx={{
                            height: `${isMobile ? '180px' : '100%'}`,
                        }}
                    >
                        <CardContent
                            className={classes.map}
                            sx={{
                                height: '100%',
                            }}
                        >
                            {isFetching && <Spinner size={50} />}
                            {!isFetching && data && points && <GoogleMap points={points} />}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            {/* Guest */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <LabeledData label="Guest" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching && (
                            <CardContent sx={{ paddingBottom: '0.5rem !important' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={isMobile ? 12 : 4}>
                                        <LabeledData
                                            label="Name"
                                            data={`${guest?.first_name ?? ''} ${guest?.last_name ?? ''}`}
                                            dataSuffix={guest?.traveller?.employee_number.toString() ?? ''}
                                            labelColumnNumber={isMobile ? 4 : 3.5}
                                            dataColumnNumber={isMobile ? 8 : 8.5}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 4}>
                                        <LabeledData
                                            label="Phone"
                                            data={`${guest?.phone_number ?? ''}`}
                                            labelColumnNumber={4}
                                            dataColumnNumber={8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 4}>
                                        <LabeledData
                                            label="Email"
                                            data={`${guest?.email ?? ''}`}
                                            labelColumnNumber={isMobile ? 4 : 2.5}
                                            dataColumnNumber={isMobile ? 8 : 9.5}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* Check In/Out */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <LabeledData label="Booking Details" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching && (
                            <CardContent sx={{ paddingBottom: '0.5rem !important' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={isMobile ? 12 : !isBackoffice ? 4 : 6}>
                                        <LabeledData
                                            label="Check-in"
                                            data={getFormattedDateTimeString(
                                                data?.checkInDateLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm'],
                                                data?.isTimeIncluded
                                            )}
                                            labelColumnNumber={isMobile ? 4 : 4}
                                            dataColumnNumber={isMobile ? 8 : 8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : !isBackoffice ? 4 : 6}>
                                        <LabeledData
                                            label="Check-out"
                                            data={getFormattedDateTimeString(
                                                data?.checkOutDateLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm'],
                                                data?.isTimeIncluded
                                            )}
                                            labelColumnNumber={isMobile ? 4 : 5}
                                            dataColumnNumber={isMobile ? 8 : 7}
                                        />
                                    </Grid>
                                    {!isBackoffice && (
                                        <Grid item xs={isMobile ? 12 : 4}>
                                            <LabeledData
                                                label="Price"
                                                data={calculateBookingPrice(data?.financeTransactions) || ''}
                                                labelColumnNumber={isMobile ? 8 : 2.5}
                                                dataColumnNumber={isMobile ? 4 : 9.5}
                                            />
                                        </Grid>
                                    )}
                                </Grid>
                            </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* Confirmation Details */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <LabeledData label="Confirmation Details" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                </Grid>
            </Grid>

            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching && (
                            <CardContent sx={{ paddingBottom: '0.5rem !important' }}>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                    <Grid item xs={isMobile ? 12 : 6}>
                                        <LabeledData
                                            label="Confirmed at"
                                            data={getFormattedDateTimeString(
                                                data?.confirmationTimeLocal ?? '',
                                                DATE_FORMATS['DD MMM YYYY'],
                                                TIME_FORMATS['HH:mm A'],
                                                true
                                            )}
                                            labelColumnNumber={isMobile ? 5 : 4}
                                            dataColumnNumber={isMobile ? 7 : 8}
                                        />
                                    </Grid>
                                    <Grid item xs={isMobile ? 12 : 6}>
                                        <LabeledData
                                            label="Confirmation Number"
                                            data={data?.bookingNumber}
                                            labelColumnNumber={isMobile ? 8 : 5}
                                            dataColumnNumber={isMobile ? 4 : 7}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* Special Request */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <LabeledData label="Special Request" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                </Grid>
            </Grid>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={12}>
                    <Card variant="outlined">
                        {isFetching && (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    display: 'flex',
                                }}
                            >
                                <Spinner size={30} />
                            </Grid>
                        )}
                        {!isFetching && (
                            <CardContent sx={{ paddingBottom: '1rem !important' }}>
                                <Typography>{isFetching ? '' : data?.notes || ''}</Typography>
                            </CardContent>
                        )}
                    </Card>
                </Grid>
            </Grid>

            {/* Hotel Comments */}
            {isBackoffice && (
                <div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                        <Grid item xs={12}>
                            <LabeledData label="Hotel Comments" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                        </Grid>
                    </Grid>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12}>
                            <Card variant="outlined">
                                {isFetching && (
                                    <Grid
                                        item
                                        xs={12}
                                        sx={{
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <Spinner size={30} />
                                    </Grid>
                                )}
                                {!isFetching && (
                                    <CardContent sx={{ paddingBottom: '1rem !important' }}>
                                        <Typography>{isFetching ? '' : data?.hotelComments || ''}</Typography>
                                    </CardContent>
                                )}
                            </Card>
                        </Grid>
                    </Grid>
                    {/* Internal comments */}
                    <Grid item xs={12} marginTop={'1rem'}>
                        <Grid item xs={12}>
                            <LabeledData label="Internal Comments" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                        </Grid>
                        <TextField
                            label="Comment"
                            value={comment}
                            onChange={event => setComment(event.target.value)}
                            placeholder="Add any comments here"
                        />
                        <Box sx={{ display: 'flex', justifyContent: 'end', marginBottom: '1rem' }}>
                            <PrimaryButton onClick={handleSubmitComment} loading={isLoadingComments || isLoadingUpdate}>
                                Upload comment
                            </PrimaryButton>
                        </Box>
                        {bookingComments?.map(commentEl => (
                            <Box key={commentEl.id} className={classes.commentBox}>
                                <Typography>{commentEl.content}</Typography>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography fontSize={'.75rem'} color={COLORS.SLATE_GREY}>
                                        Created at: {dayjs(commentEl.createdAt).local().format(DATE_FORMATS['DD MMM YYYY HH:mm'])}{' '}
                                        Local
                                    </Typography>
                                    <Typography fontSize={'.75rem'} color={COLORS.SLATE_GREY} component={'span'}>
                                        By: {commentEl.user.firstName} {commentEl.user.lastName}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                    </Grid>
                </div>
            )}

            {/* Benefits */}
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                <Grid item xs={12}>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <LocalParkingOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5} sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}>
                            <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                                {includeParking}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ paddingTop: '0' }}>
                            <LocalCafeOutlinedIcon fontSize="small" />
                        </Grid>
                        <Grid item xs={5} sx={{ paddingTop: '0', paddingLeft: '0.75rem !important' }}>
                            <Typography sx={{ fontSize: '1rem' }} color="text.secondary" gutterBottom>
                                {includeBreakfast}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/* Finance */}
            {isBackoffice && (
                <div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                        <Grid item xs={12}>
                            <LabeledData label="Finance" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                        </Grid>
                    </Grid>
                    <TransactionTable transactions={data?.financeTransactions}></TransactionTable>
                </div>
            )}

            {/* Change Logs */}
            {isBackoffice && data?.changeLogs && (
                <div>
                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '1rem' }}>
                        <Grid item xs={12}>
                            <LabeledData label="Change Logs" labelColor={COLORS.BLUE} labelColumnNumber={12} />
                        </Grid>
                    </Grid>
                    <ChangeLogTable changeLogs={data?.changeLogs}></ChangeLogTable>
                </div>
            )}
        </>
    );
};

export default ViewBooking;
