import { Benefit, FinanceTransaction, RoomType, TravellerResponse } from '../../hotels/api/types';

export interface Guest {
    id: number;
    request_id: number;
    traveller?: TravellerResponse;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
}

export interface BookingGuest {
    id: number;
    booking_id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    traveller?: TravellerResponse;
}

export interface EditRequest extends CreateRequest {
    id: string;
}

export enum BookingStatus {
    SEARCHING_ROOMS = 'Searching rooms',
    BOOKING_PENDING = 'Pending',
    BOOKING_UPDATE_PENDING = 'Pending update',
    PROCESS_CANCELLATION = 'Process cancellation',
    BOOKING_CONFIRMED = 'Confirmed',
    BOOKING_CANCELLED = 'Cancelled',
    NOT_AVAILABLE = 'Not available',
    COMPLETED = 'Completed',
}

export interface Booking {
    id: number;
    checkInDateLocal: string;
    checkOutDateLocal: string;
    customerId: number;
    customerName: string;
    estimatedArrivalTime: string;
    hotelAddressLine: string;
    hotelCity: string;
    hotelCountryName: string;
    hotelId: number;
    hotelLatitude: string;
    hotelLongitude: string;
    hotelName: string;
    hotelPostalCode: string;
    hotelStateProvince: string;
    notes: string;
    bookingGuests: BookingGuest[];
    bookingNumber: string;
    hotelComments: string;
    confirmationTimeLocal: string;
    status: BookingStatus;
    timeZone: string;
    isTimeIncluded: boolean;
    hotelStandardCheckIn: string;
    hotelStandardCheckOut: string;
    benefits: Benefit[];
    financeTransactions: FinanceTransaction[];
    isAutomated: boolean;
    isIgnoredBySynchronizer: boolean;
    customFields: BookingCustomField[];
    units: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    pendingUpdates: any;
    changeLogs: ChangeLog[];
    timeToActionZulu?: string;
    financeInvoiceBookings?: number[];
}

export interface BookingCustomField {
    key: string;
    label: string;
    type: 'BOOLEAN' | 'STRING' | 'ENUM';
    value: string;
}

export interface RequestAddress {
    id: number;
    short_name: string;
    country: {
        id: number;
        name: string;
        alpha2: string;
        alpha3: string;
        numeric: number;
    };
    address_line: string;
    postal_code: string;
    city: string;
    state_province: string;
    full_address: string;
    lat: string;
    long: string;
}

export interface Request {
    id: number;
    request_address: RequestAddress;
    guests: Guest[];
    bookings: Booking[];
    status: string;
    check_in_date_local: string;
    check_out_date_local: string;
    is_time_included: boolean;
    comments: string;
    room_types: RoomType[];
    benefits: Benefit[];
    minimum_star_rating: number;
    customer_name: string;
    customer_id: number;
}

export interface BookingVariables {
    page?: number;
    customerId?: number;
    activeTab?: 'all' | 'pending' | 'in_progress' | 'upcoming' | 'archive';
    searchType?: 'request' | 'traveller' | 'address' | 'hotel';
    searchValue?: string;
    startDate?: string;
    endDate?: string;
}

export interface PaginationType {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    to: number;
    total_pending: number;
    total_in_progress: number;
    total_upcoming: number;
    total_archived: number;
}

export interface SearchOption {
    search_value: string;
    main_display_value: string;
    secondary_display_value: string;
    group_type: string;
}

export interface SearchResponse {
    request: SearchOption[];
    traveller: SearchOption[];
    address: SearchOption[];
    hotel: SearchOption[];
}

export interface CreateRequest {
    checkInDate: string;
    checkOutDate: string;
    isTimeIncluded: boolean;
    minimumStarRating: number;
    comments: string;
    roomTypeIds: string[];
    benefitIds: string[];
    requestAddress: {
        shortName: string;
        addressLine: string;
        postalCode: string;
        city: string;
        stateProvince: string;
        countryCode: string;
        lat: string;
        long: string;
        timeZone: string;
    };
    travellerId: string;
    checkInTime: string;
    checkOutTime: string;
    customerId: number | null;
}

export enum Benefits {
    INCLUDE_BREAKFAST = 'Include breakfast',
    INCLUDE_PARKING = 'Include parking',
}

export enum RoomTypes {
    SINGLE_ROOM = 'Single room',
    DOUBLE_ROOM = 'Double room',
    MEETING_ROOM = 'Meeting room',
}

export interface CreateBooking {
    status: keyof typeof BookingStatus;
    requestId: number;
    hotelId: number | string;
    checkInDate: string;
    checkOutDate: string;
    isTimeIncluded: boolean;
    checkInTime: string | null;
    checkOutTime: string | null;
    notes: string;
    bookingNumber: string | null;
    guestId: number;
    sendEmailRequestedToHotel: boolean;
    sendEmailUpdatedToHotel: boolean;
    sendEmailCancelledToHotel: boolean;
    sendEmailUpdatedToBooker: boolean;
    sendEmailCancelledToBooker: boolean;
    sendEmailConfirmedToBooker: boolean;
    sendEmailUpdatedToTraveller: boolean;
    sendEmailCancelledToTraveller: boolean;
    sendEmailConfirmedToTraveller: boolean;
    benefitIds: number[];
}

export interface EditBooking {
    id: number;
    payload: {
        status: keyof typeof BookingStatus;
        hotelId: number | string;
        checkInDate: string;
        checkOutDate: string;
        isTimeIncluded: boolean;
        checkInTime: string | null;
        checkOutTime: string | null;
        notes: string;
        hotelComments: string | null;
        bookingNumber: string | null;
        guestId: number;
        sendEmailRequestedToHotel: boolean;
        sendEmailUpdatedToHotel: boolean;
        sendEmailCancelledToHotel: boolean;
        sendEmailUpdatedToBooker: boolean;
        sendEmailCancelledToBooker: boolean;
        sendEmailConfirmedToBooker: boolean;
        sendEmailUpdatedToTraveller: boolean;
        sendEmailCancelledToTraveller: boolean;
        sendEmailConfirmedToTraveller: boolean;
        benefitIds: number[];
    };
}

export interface SearchOptionsForBookingResponse {
    traveller: SearchOption[];
    address: SearchOption[];
    hotel: SearchOption[];
}

export interface ConfirmBooking {
    payload: {
        list: BookingConfirmation[];
    };
}

export interface BookingConfirmation {
    id: number;
    status: keyof typeof BookingStatus;
    bookingNumber: string | null;
    hotelComments: string | null;
}

export interface ChangeLog {
    confirmation: object | null;
    createdAt: string;
    details: ChangedAttribute;
    user: ChangedAttribute;
    version: number;
}

interface ChangedAttribute {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export enum NotificationSetting {
    'PENDING_BOOKING' = 'Pending Bookings',
    'URGENT_PENDING_BOOKING' = 'Urgent Pending Bookings',
    'STATEMENT_GENERATED' = 'Statement generated',
}

export interface BookingComment {
    id: number;
    user: {
        id: number;
        firstName: string;
        lastName: string;
    };
    content: string;
    createdAt: string;
}

export interface CommentPayload {
    id: number;
    content: string;
}
