import { useQuery } from 'react-query';

import { GET_CURRENCY } from '../../../../constans/queryKeys';
import apiClient from '../../../../services/api';
import { CURRENCY } from '../../../../services/routes';
import { Currency } from '../../api/types';

export const useCurrency = (search?: string) => {
    const { data, isLoading, isError, isFetching, refetch } = useQuery([GET_CURRENCY + search], () => getCurrency(search), {
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        retry: 1,
    });

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
    };
};

const getCurrency = async (search?: string): Promise<Currency[]> => {
    const searchParams = new URLSearchParams({ search: search ?? '' }).toString();
    const { data } = await apiClient.get<Currency[]>(`${CURRENCY}?${searchParams}`);

    return data;
};
