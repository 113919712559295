export const typeToExtensionMap = {
    // Images
    'image/jpeg': 'jpg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/webp': 'webp',
    'image/bmp': 'bmp',
    'image/svg+xml': 'svg',
    'image/tiff': 'tiff',
    'image/x-icon': 'ico',

    // Videos
    'video/mp4': 'mp4',
    'video/webm': 'webm',
    'video/ogg': 'ogv',
    'video/x-msvideo': 'avi',
    'video/mpeg': 'mpeg',
    'video/quicktime': 'mov',

    // Audio
    'audio/mpeg': 'mp3',
    'audio/ogg': 'ogg',
    'audio/wav': 'wav',
    'audio/webm': 'weba',
    'audio/x-aac': 'aac',
    'audio/x-wav': 'wav',

    // Documents
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'application/vnd.ms-powerpoint': 'ppt',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
    'text/plain': 'txt',
    'text/csv': 'csv',
    'text/html': 'html',
    'application/json': 'json',
    'application/rtf': 'rtf',
    'application/xml': 'xml',

    // Archives
    'application/zip': 'zip',
    'application/x-7z-compressed': '7z',
    'application/x-rar-compressed': 'rar',
    'application/gzip': 'gz',
    'application/x-tar': 'tar',

    // Scripts and Code
    'application/javascript': 'js',
    'application/x-python-code': 'py',
    'application/x-sh': 'sh',
    'text/css': 'css',
    'text/javascript': 'js',
    'text/markdown': 'md',

    // Miscellaneous
    'application/octet-stream': 'bin', // Default for unknown binary files
    'application/vnd.android.package-archive': 'apk',
    'application/x-iso9660-image': 'iso',
    'application/x-msdownload': 'exe',
    'application/x-dosexec': 'exe',
};
