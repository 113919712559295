import { useQuery } from 'react-query';

import { GET_BOOOKING_CHANNELS } from '../../../../constans/queryKeys';
import apiClient from '../../../../services/api';
import { BOOOKING_CHANNELS } from '../../../../services/routes';
import { Entity } from '../../api/types';

export const useBookingChannels = () => {
    const { data, isLoading, isError, isFetching, refetch, isFetched } = useQuery(
        [GET_BOOOKING_CHANNELS],
        () => getBookingChannels(),
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            retry: 1,
        }
    );

    return {
        data,
        isLoading,
        isError,
        isFetching,
        refetch,
        isFetched,
    };
};

const getBookingChannels = async (): Promise<Entity[]> => {
    const { data } = await apiClient.get<Entity[]>(BOOOKING_CHANNELS);

    return data;
};
