import downloadFile from '../../../helpers/downloadFile';
import { typeToExtensionMap } from '../../../helpers/typeToExtensionMap';
import apiClient from '../../../services/api';
import { getDownloadInvoiceFileRoute } from '../../../services/routes';

const downloadInvoiceFile = async ({
    id,
    fileId,
    fileName,
}: {
    id: string;
    fileId: number;
    fileName?: string;
}): Promise<unknown> => {
    const result = await apiClient
        .get(getDownloadInvoiceFileRoute(parseInt(id), fileId), { responseType: 'blob' })
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));

            const fileType = response?.data?.type;
            const fileExtension = typeToExtensionMap[fileType as keyof typeof typeToExtensionMap] || 'bin';

            downloadFile(url, `${fileName}.${fileExtension}`);
        })
        .catch(error => {
            console.log(error);
        });

    return result;
};

export default downloadInvoiceFile;
