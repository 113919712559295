import { SecondaryButton } from '@get-e/react-components';
import { Download } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles(() => ({
    buttonStyle: { color: COLORS.BLUE },
}));

export const CustomFooter = ({ handleDownload }: { handleDownload: () => void }) => {
    const classes = useStyles();

    return (
        <GridFooterContainer sx={{ display: 'flex', justifyContent: 'space-between', padding: '0 2rem' }}>
            <SecondaryButton onClick={() => handleDownload()} className={classes.buttonStyle}>
                <Download sx={{ fontSize: '1.5rem', marginRight: '.5rem' }} /> Download CSV
            </SecondaryButton>
            <GridPagination />
        </GridFooterContainer>
    );
};
