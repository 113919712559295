import { InsertDriveFile } from '@mui/icons-material';
import { Box, Grid, Typography } from '@mui/material';

import { COLORS } from '../../../../constans/colors';
import { File as FileType } from '../../api/types';

interface FileItemProps {
    file: File | FileType;
    handlePreview: () => void;
    handleRemove?: () => void;
    isSelected?: boolean;
    handleDownload?: () => void;
}

export const FileItem = ({ file, handlePreview, handleRemove, isSelected, handleDownload }: FileItemProps) => (
    <Grid
        container
        sx={{
            alignItems: 'center',
            marginBottom: '1rem',
        }}
    >
        <Grid
            item
            sx={{
                backgroundColor: COLORS.LIGHT_GRAY,
                borderRadius: '.3rem',
                padding: '1rem',
                marginRight: '1rem',
            }}
        >
            <InsertDriveFile sx={{ fontSize: '1.5rem', color: COLORS.SLATE_GREY }} />
        </Grid>
        <Grid
            item
            xs={8}
            sx={{
                cursor: 'pointer',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
            }}
        >
            <Typography fontSize="0.8rem" fontWeight={700} paddingBottom={'3px'} color={isSelected ? COLORS.GREEN : COLORS.BLACK}>
                {file.name}
            </Typography>
            <Typography fontSize="0.8rem" paddingBottom={'3px'} color={COLORS.SLATE_GREY}>
                {(file as FileType)?.createdAt ? `Upload time: ${new Date((file as FileType)?.createdAt).toLocaleString()}` : ''}
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <Box
                    onClick={handlePreview}
                    sx={{
                        textTransform: 'none',
                        fontSize: '0.75rem',
                        marginRight: '0.5rem',
                        padding: 0,
                        color: COLORS.BLUE,
                        display: 'flex',
                        fontWeight: 700,
                    }}
                >
                    {isSelected ? 'Viewing' : 'Preview'}
                </Box>
                {handleDownload && (
                    <Box
                        onClick={handleDownload}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.75rem',
                            marginRight: '0.5rem',
                            padding: 0,
                            color: COLORS.SLATE_GREY,
                            fontWeight: 700,
                        }}
                    >
                        Download
                    </Box>
                )}
                {handleRemove && (
                    <Box
                        onClick={handleRemove}
                        sx={{
                            textTransform: 'none',
                            fontSize: '0.75rem',
                            marginRight: '0.5rem',
                            padding: 0,
                            color: COLORS.RED,
                            fontWeight: 700,
                        }}
                    >
                        Delete
                    </Box>
                )}
            </Box>
        </Grid>
    </Grid>
);
