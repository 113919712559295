import dayjs from 'dayjs';

export default function getDueInTime(dueTime: string) {
    const timeNow = dayjs.utc();
    const dueTimeMoment = dayjs.utc(dueTime);

    const timeUntilRide = dueTimeMoment.diff(timeNow, 'minutes');
    const countdown = calculateCountdown(timeUntilRide);

    return countdown;
}

const MINUTES_IN_HOUR = 60;
const HOURS_IN_DAY = 24;

export const calculateCountdown = (time: number) => {
    let countdown = '';
    const dueInHours = Math.abs(Math.floor(time / MINUTES_IN_HOUR));
    const dueInMinutes = Math.abs(time % MINUTES_IN_HOUR);
    const dueInDays = Math.abs(Math.floor(dueInHours / HOURS_IN_DAY));

    if (time > 0) {
        if (time < MINUTES_IN_HOUR) {
            countdown = `Due in ${dueInMinutes} min`;
        } else if (dueInHours > HOURS_IN_DAY) {
            countdown = `Due in ${dueInDays} days`;
        } else {
            countdown = `Due in ${dueInHours} h ${dueInMinutes === 0 ? '' : `${dueInMinutes} min `}`;
        }
    } else if (time === 0) {
        countdown = 'Due now';
    } else if (Math.abs(time) < MINUTES_IN_HOUR) {
        countdown = `Due ${dueInMinutes} min ago`;
    } else if (dueInHours >= HOURS_IN_DAY) {
        countdown = `Due ${dueInDays} days ago`;
    } else {
        countdown = `Due ${dueInHours} h ${dueInMinutes === 0 ? '' : `${dueInMinutes} min `}ago`;
    }

    return countdown;
};
