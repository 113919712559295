import { SxProps, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

import { COLORS } from '../../../../constans/colors';

const useStyles = makeStyles(() => ({
    heading: {
        color: COLORS.BLUE,
        fontSize: '1rem',
        fontWeight: 700,
        marginBottom: '1rem',
        display: 'flex',
        alignItems: 'center',
    },
}));

export const SectionHeader = ({ title, sx }: { title: string; sx?: SxProps }) => {
    const classes = useStyles();

    return (
        <Typography variant="h3" component="h4" className={classes.heading} sx={sx}>
            {title}
        </Typography>
    );
};
