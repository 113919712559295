export enum StatusOption {
    UNDER_REVIEW = 'UNDER_REVIEW',
    READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
    APPROVED = 'APPROVED',
    DISPUTED = 'DISPUTED',
    REJECTED = 'REJECTED',
}

export interface InvoiceVariables {
    search?: string;
    fromDateLocal?: string;
    toDateLocal?: string;
    limit?: number;
    page?: number;
    statuses: string[];
    bookingChannelIds?: string[];
    onlyCanceledBookings?: boolean;
    onlyNotDownloaded?: boolean;
}

export interface UpdateStatusVariables {
    id: string;
    payload: {
        status: string;
    };
}

export interface CommentVariables {
    id: string;
    content: string;
}
