import { ReceiptLongOutlined } from '@mui/icons-material';
import { Tooltip, Box, Typography, Link } from '@mui/material';

import { COLORS } from '../constans/colors';
import { CUSTOMER_INVOICE_BASE_URL, EDIT_INVOICE_BASE_URL } from '../constans/urlPaths';
import { useIsBackoffice } from '../context/AuthenticatedUserContext';

interface InvoicedTooltipProps {
    invoiceIds: number[];
    iconSize?: string;
}

export const InvoicedTooltip = ({ invoiceIds, iconSize }: InvoicedTooltipProps) => {
    const isBackoffice = useIsBackoffice();

    const baseUrlLink = isBackoffice ? EDIT_INVOICE_BASE_URL : CUSTOMER_INVOICE_BASE_URL;

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <Tooltip
            title={
                <Box display={'flex'}>
                    <Typography sx={{ fontSize: '.8rem' }}>Already invoiced:&nbsp;</Typography>
                    {invoiceIds.map((id, index) => (
                        <Box key={index}>
                            <Link
                                href={`${baseUrlLink}${id}`}
                                target="_blank"
                                sx={{ cursor: 'pointer', color: COLORS.WHITE, fontSize: '1rem', textDecoration: 'underline' }}
                                onClick={handleClick}
                            >
                                {id}
                                {index < invoiceIds.length - 1 && ', '}
                            </Link>
                        </Box>
                    ))}
                </Box>
            }
            placement="top"
            arrow
        >
            <ReceiptLongOutlined sx={{ color: COLORS.GREEN, fontSize: iconSize || '1rem' }} />
        </Tooltip>
    );
};
